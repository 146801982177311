// src/pages/NotAllowed.js

import React from 'react';


const NotAllowed = () => {
  return (
    <div style={styles.container}>
      <h1>Access Restricted</h1>
      <br />
      <br />
      <img src="/fingers_qr.png" width="200px" />
      <br />
      <p>
        This application is only accessible via the mobile Telegram browser. <br />Please open the app in Telegram to continue.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px',
  },
};

export default NotAllowed;
