import React, { useState, useEffect } from 'react';

const gameUrls = [
  { label: "Super Mining", value: "/moonbix" },
  { label: "Spin", value: "/roulette" },
  { label: "FatMonkey", value: "/FatBoy" },
  { label: "Jungle Invaders", value: "/aliens" },
  { label: "Jungle loops", value: "/hexa-game" },
];

const AdminGameEvents = () => {
  const [events, setEvents] = useState([]);
  const [eventData, setEventData] = useState({
    name: '',
    description: '',
    icon: '',
    rewards: '',
    url: '',
    startDate: '',
    endDate: '',
    isActive: false
  });
  const [showEventInputs, setShowEventInputs] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [currentEventId, setCurrentEventId] = useState('');
  const [loading, setLoading] = useState(true);

  const [showLeaderboardModal, setShowLeaderboardModal] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setLoading(true);
    const response = await fetch('https://fmguardians.com/backend/manage_events.php?action=fetch');
    const data = await response.json();
    setEvents(data);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'isActive') {
      setEventData({
        ...eventData,
        isActive: value === 'true'
      });
    } else {
      setEventData({
        ...eventData,
        [name]: value
      });
    }
  };

  const handleAddEvent = async () => {
    const formData = new FormData();
    formData.append('action', 'add');
    Object.keys(eventData).forEach(key => {
      formData.append(key, eventData[key]);
    });

    const response = await fetch('https://fmguardians.com/backend/manage_events.php', {
      method: 'POST',
      body: formData
    });
    const result = await response.json();

    if (result.success) {
      setSuccessMessage('Event successfully added!');
      resetForm();
      fetchEvents();
    } else {
      console.error("Error adding event: ", result.message);
    }
  };

  const handleUpdateEvent = async () => {
    const formData = new FormData();
    formData.append('action', 'update');
    formData.append('id', currentEventId);
    Object.keys(eventData).forEach(key => {
      formData.append(key, eventData[key]);
    });

    const response = await fetch('https://fmguardians.com/backend/manage_events.php', {
      method: 'POST',
      body: formData
    });
    const result = await response.json();

    if (result.success) {
      setSuccessMessage('Event successfully updated!');
      resetForm();
      fetchEvents();
    } else {
      console.error("Error updating event: ", result.message);
    }
  };

  const handleDeleteEvent = async (id) => {
    const formData = new FormData();
    formData.append('action', 'delete');
    formData.append('id', id);

    const response = await fetch('https://fmguardians.com/backend/manage_events.php', {
      method: 'POST',
      body: formData
    });
    const result = await response.json();

    if (result.success) {
      fetchEvents();
    } else {
      console.error("Error deleting event: ", result.message);
    }
  };

  const resetForm = () => {
    setEventData({ name: '', description: '', icon: '', rewards: '', url: '', startDate: '', endDate: '', isActive: false });
    setShowEventInputs(false);
    setIsEditing(false);
    setCurrentEventId('');
  };

  const handleEditEvent = (event) => {
    setEventData({
      name: event.name || '',
      description: event.description || '',
      icon: event.icon || '',
      rewards: event.rewards || '',
      url: event.url || '',
      startDate: event.startDate || '',
      endDate: event.endDate || '',
      isActive: event.isActive || false
    });
    setShowEventInputs(true);
    setIsEditing(true);
    setCurrentEventId(event.id);
  };

  const handleShowLeaderboard = async (eventId) => {
    const response = await fetch('https://fmguardians.com/backend/manage_events.php?action=fetch');
    const data = await response.json();
    const event = data.find(e => e.id === eventId);
    if (event && Array.isArray(event.leaderboard)) {
      setLeaderboardData(event.leaderboard);
    } else {
      setLeaderboardData([]);
    }
    setShowLeaderboardModal(true);
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="w-full flex flex-col h-screen overflow-y-auto p-4">
          <button
            onClick={() => setShowEventInputs(!showEventInputs)}
            className="bg-green-500 text-white font-semibold px-4 py-2 rounded-md mb-4"
          >
            {showEventInputs ? 'Cancel' : 'New Event'}
          </button>

          {showEventInputs && (
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-sm font-medium mb-1">Event Name</label>
                <input
                  type="text"
                  name="name"
                  value={eventData.name}
                  onChange={handleInputChange}
                  placeholder="Event Name"
                  className="border rounded w-full px-2 py-1 text-black"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <input
                  type="text"
                  name="description"
                  value={eventData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                  className="border rounded w-full px-2 py-1 text-black"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Icon URL</label>
                <input
                  type="text"
                  name="icon"
                  value={eventData.icon}
                  onChange={handleInputChange}
                  placeholder="Icon URL"
                  className="border rounded w-full px-2 py-1 text-black"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Rewards</label>
                <input
                  type="text"
                  name="rewards"
                  value={eventData.rewards}
                  onChange={handleInputChange}
                  placeholder="Rewards"
                  className="border rounded w-full px-2 py-1 text-black"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Game URL</label>
                <select
                  name="url"
                  value={eventData.url}
                  onChange={handleInputChange}
                  className="border rounded w-full px-2 py-1 text-black"
                >
                  <option value="">Select Game URL</option>
                  {gameUrls.map((game) => (
                    <option key={game.value} value={game.value}>
                      {game.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={eventData.startDate}
                  onChange={handleInputChange}
                  className="border rounded w-full px-2 py-1 text-black"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  value={eventData.endDate}
                  onChange={handleInputChange}
                  className="border rounded w-full px-2 py-1 text-black"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Active Status</label>
                <select
                  name="isActive"
                  value={eventData.isActive ? "true" : "false"}
                  onChange={handleInputChange}
                  className="border rounded w-full px-2 py-1 text-black"
                >
                  <option value="false">Inactive</option>
                  <option value="true">Active</option>
                </select>
              </div>
              <div className="col-span-2">
                <button
                  onClick={isEditing ? handleUpdateEvent : handleAddEvent}
                  className="bg-blue-500 text-white font-semibold px-4 py-2 rounded-md w-full"
                >
                  {isEditing ? 'Update Event' : 'Add Event'}
                </button>
              </div>
            </div>
          )}

          {successMessage && <div className="text-green-500 mb-4">{successMessage}</div>}

          <div className="grid grid-cols-3 gap-4">
            {events.map((event) => (
              <div key={event.id} className="bg-gray-800 text-white p-4 rounded-md shadow">
                <h3 className="font-bold text-xl">{event.name}</h3>
                <p>{event.description}</p>
                <p className="text-sm text-gray-400">{event.url}</p>
                <p className="text-sm text-gray-400">Start: {event.startDate || 'N/A'}</p>
                <p className="text-sm text-gray-400">End: {event.endDate || 'N/A'}</p>
                <p className="text-sm text-gray-400">Active: {event.isActive ? 'Yes' : 'No'}</p>
                <div className="flex space-x-2 mt-4">
                  <button
                    onClick={() => handleEditEvent(event)}
                    className="bg-yellow-500 text-black px-2 py-1 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteEvent(event.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => handleShowLeaderboard(event.id)}
                    className="bg-blue-500 text-white px-2 py-1 rounded"
                  >
                    Leaderboard
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showLeaderboardModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow max-w-sm w-full">
            <h2 className="text-xl font-bold mb-2" style={{color:"#333333"}}>Leaderboard</h2>
            <ul className="mb-4" style={{maxHeight:"500px", overflowY:"scroll"}}>
              {leaderboardData.length > 0 ? (
                leaderboardData.map((entry, index) => (
                  <li key={index} style={{color:"#333333"}}>
                    ({index}.)  {entry.username} - {entry.points}
                  </li>
                ))
              ) : (
                <li>No entries yet.</li>
              )}
            </ul>
            <button
              onClick={() => setShowLeaderboardModal(false)}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminGameEvents;
