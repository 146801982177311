import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore';
import { 
  getDocs, 
  doc, 
  updateDoc, 
  deleteDoc, 
  query, 
  orderBy, 
  limit, 
  collection, 
  startAfter, 
  getCountFromServer,
  where
} from "firebase/firestore";

const PAGE_SIZE = 5000;

const UserManagementPanel = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Editing user
  const [user, setUser] = useState(null);
  const [editUserData, setEditUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Filters
  const [levelFilter, setLevelFilter] = useState('');
  const [pphFrom, setPphFrom] = useState('');
  const [pphTo, setPphTo] = useState('');
  const [tokenFrom, setTokenFrom] = useState('');
  const [tokenTo, setTokenTo] = useState('');
  const [lastLoginFilter, setLastLoginFilter] = useState('');

  // Sorting
  const [sortField, setSortField] = useState('balance');
  const [sortOrder, setSortOrder] = useState('desc'); // desc or asc

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCursors, setPageCursors] = useState({1: null});

  // Keep track if we are in search mode
  const [isSearchMode, setIsSearchMode] = useState(false);

  useEffect(() => {
    if (!isSearchMode) {
      // Count total documents and load the first page initially
      const countDocuments = async () => {
        try {
          const collRef = collection(db, "telegramUsers");
          const snapshot = await getCountFromServer(collRef);
          const totalDocs = snapshot.data().count;
          const pages = Math.ceil(totalDocs / PAGE_SIZE);
          setTotalPages(pages);
          await loadPage(1);
        } catch (error) {
          console.error("Error counting documents: ", error);
          setErrorMessage('Error counting documents');
        }
      };
      countDocuments();
    }
  }, [isSearchMode]);

  const loadPage = async (pageNumber) => {
    if (loading) return;
    setLoading(true);

    try {
      const usersRef = collection(db, "telegramUsers");
      if (pageNumber === 1) {
        // Load first page
        const q = query(usersRef, orderBy("balance", "desc"), limit(PAGE_SIZE));
        const userSnapshot = await getDocs(q);
        const fetchedUsers = userSnapshot.docs.map(d => ({
          id: d.id,
          ...d.data()
        }));
        setUsers(fetchedUsers);

        const lastDoc = userSnapshot.docs[userSnapshot.docs.length - 1];
        setPageCursors({1: null, 2: lastDoc});
      } else {
        // If we have visited page before, we should have its cursor (or at least a closer page)
        let closestPage = pageNumber - 1;
        while (closestPage > 1 && !pageCursors[closestPage]) {
          closestPage--;
        }

        let lastDoc = pageCursors[closestPage]; 
        // Fetch pages from closestPage until pageNumber
        for (let p = closestPage; p < pageNumber; p++) {
          const q = query(usersRef, orderBy("balance", "desc"), startAfter(lastDoc), limit(PAGE_SIZE));
          const userSnapshot = await getDocs(q);
          if (userSnapshot.empty) break;
          const fetchedUsers = userSnapshot.docs.map(d => ({
            id: d.id,
            ...d.data()
          }));
          lastDoc = userSnapshot.docs[userSnapshot.docs.length - 1];
          setPageCursors(prev => ({ ...prev, [p+1]: lastDoc }));
          if (p+1 === pageNumber) {
            setUsers(fetchedUsers);
          }
        }
      }

      setCurrentPage(pageNumber);
    } catch (error) {
      console.error("Error fetching users: ", error);
      setErrorMessage('Error fetching users');
    } finally {
      setLoading(false);
    }
  };

  // Filters, sorting, searching
  useEffect(() => {
    let temp = [...users];

    // Search filter is now handled by Firestore in handleSearch(), but if needed, you can still do client-side filtering here.
    if (searchTerm.trim() !== '' && isSearchMode) {
      const lower = searchTerm.toLowerCase();
      temp = temp.filter(u =>
        (u.username && u.username.toLowerCase().includes(lower)) ||
        u.id.toLowerCase().includes(lower)
      );
    }

    // Level filter
    if (levelFilter) {
      temp = temp.filter(u => u.level && u.level.name === levelFilter);
    }

    // PPH filter from-to
    const pphFromVal = pphFrom ? parseInt(pphFrom, 10) : null;
    const pphToVal = pphTo ? parseInt(pphTo, 10) : null;
    if (pphFromVal !== null) {
      temp = temp.filter(u => (u.profitHour || 0) >= pphFromVal);
    }
    if (pphToVal !== null) {
      temp = temp.filter(u => (u.profitHour || 0) <= pphToVal);
    }

    // Token filter from-to
    const tokenFromVal = tokenFrom ? parseInt(tokenFrom, 10) : null;
    const tokenToVal = tokenTo ? parseInt(tokenTo, 10) : null;
    if (tokenFromVal !== null) {
      temp = temp.filter(u => (u.balance || 0) >= tokenFromVal);
    }
    if (tokenToVal !== null) {
      temp = temp.filter(u => (u.balance || 0) <= tokenToVal);
    }

    // Last login filter
    const now = new Date();
    if (lastLoginFilter === 'today') {
      temp = temp.filter(u => {
        if (!u.lastLogin) return false;
        const loginDate = new Date(u.lastLogin);
        return loginDate.toDateString() === now.toDateString();
      });
    } else if (lastLoginFilter === 'lastWeek') {
      const oneWeekAgo = new Date(now.getTime() - 7*24*60*60*1000);
      temp = temp.filter(u => {
        if (!u.lastLogin) return false;
        const loginDate = new Date(u.lastLogin);
        return loginDate >= oneWeekAgo;
      });
    } else if (lastLoginFilter === 'lastMonth') {
      const oneMonthAgo = new Date(now.getFullYear(), now.getMonth()-1, now.getDate());
      temp = temp.filter(u => {
        if (!u.lastLogin) return false;
        const loginDate = new Date(u.lastLogin);
        return loginDate >= oneMonthAgo;
      });
    }

    // Sort
    temp.sort((a, b) => {
      let valA = a[sortField];
      let valB = b[sortField];

      if (valA === undefined) valA = '';
      if (valB === undefined) valB = '';

      if (typeof valA === 'string') valA = valA.toLowerCase();
      if (typeof valB === 'string') valB = valB.toLowerCase();

      if (valA < valB) return sortOrder === 'asc' ? -1 : 1;
      if (valA > valB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredUsers(temp);
  }, [users, searchTerm, levelFilter, pphFrom, pphTo, tokenFrom, tokenTo, lastLoginFilter, sortField, sortOrder, isSearchMode]);

  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      setErrorMessage('Please enter a search term');
      return;
    }

    setErrorMessage('');
    setSuccessMessage('');
    setLoading(true);

    try {
      const usersRef = collection(db, "telegramUsers");
      // Try searching by username
      let q = query(
        usersRef,
        where('username', '>=', searchTerm),
        where('username', '<=', searchTerm + '\uf8ff'),
        limit(PAGE_SIZE)
      );
      let userSnapshot = await getDocs(q);
      let fetchedUsers = userSnapshot.docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      // If no results by username, try searching by id
      if (fetchedUsers.length === 0) {
        q = query(
          usersRef,
          where('id', '>=', searchTerm),
          where('id', '<=', searchTerm + '\uf8ff'),
          limit(PAGE_SIZE)
        );
        userSnapshot = await getDocs(q);
        fetchedUsers = userSnapshot.docs.map(d => ({
          id: d.id,
          ...d.data()
        }));
      }

      setIsSearchMode(true);
      setUsers(fetchedUsers);

      // Since it's a search, we just show these results:
      const totalDocs = fetchedUsers.length;
      const pages = Math.ceil(totalDocs / PAGE_SIZE) || 1;
      setTotalPages(pages);
      setCurrentPage(1);
      setPageCursors({1: null});
    } catch (error) {
      console.error("Error searching users: ", error);
      setErrorMessage('Error searching users');
    } finally {
      setLoading(false);
    }
  };

  const handleClearSearch = async () => {
    setSearchTerm('');
    setIsSearchMode(false);
    setErrorMessage('');
    setSuccessMessage('');
    // This will re-trigger the effect to load original paginated data
  };

  const handleUpdateUser = async () => {
    try {
      const userDocRef = doc(db, "telegramUsers", user.id);
      await updateDoc(userDocRef, editUserData);
      setSuccessMessage('User successfully updated!');
      setUsers(prevUsers => prevUsers.map(u => u.id === user.id ? { ...u, ...editUserData } : u));
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user: ", error);
      setErrorMessage('Error updating user');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const userDocRef = doc(db, "telegramUsers", userId);
      await deleteDoc(userDocRef);
      setUsers(users.filter(user => user.id !== userId));
      setSuccessMessage('User successfully deleted!');
    } catch (error) {
      console.error("Error deleting user: ", error);
      setErrorMessage('Error deleting user');
    }
  };

  const formatNumber = (num) => {
    if (num === undefined || num === null) return 0;
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3) + " M";
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handlePageClick = async (pageNumber) => {
    if (isSearchMode) return; // Disable pagination when in search mode, or handle differently if needed.
    if (pageNumber < 1 || pageNumber > totalPages) return;
    await loadPage(pageNumber);
  };

  const handleFirstPage = async () => {
    if (!isSearchMode) {
      await loadPage(1);
    }
  };

  const handleLastPage = async () => {
    if (!isSearchMode) {
      await loadPage(totalPages);
    }
  };

  const handleNextPage = async () => {
    if (!isSearchMode && currentPage < totalPages) {
      await loadPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (!isSearchMode && currentPage > 1) {
      await loadPage(currentPage - 1);
    }
  };

  const renderPagination = () => {
    // If in search mode, no pagination. Adjust as needed.
    if (isSearchMode) return null;

    if (totalPages <= 20) {
      // If we have 20 or fewer total pages, show them all
      return (
        <div className="flex flex-wrap justify-center mt-4 space-x-1">
          <button 
            onClick={handleFirstPage} 
            disabled={currentPage === 1} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            First
          </button>
          <button 
            onClick={handlePrevPage} 
            disabled={currentPage === 1} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            Prev
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={`px-2 py-1 rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}
            >
              {page}
            </button>
          ))}
          <button 
            onClick={handleNextPage} 
            disabled={currentPage === totalPages} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            Next
          </button>
          <button 
            onClick={handleLastPage} 
            disabled={currentPage === totalPages} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            Last
          </button>
        </div>
      );
    } else {
      // More than 20 pages:
      // Show first 10 pages, then "...", then last 10 pages
      const firstTen = Array.from({ length: 10 }, (_, i) => i + 1);
      const lastTen = Array.from({ length: 10 }, (_, i) => totalPages - 9 + i);

      return (
        <div className="flex flex-wrap justify-center mt-4 space-x-1">
          <button 
            onClick={handleFirstPage} 
            disabled={currentPage === 1} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            First
          </button>
          <button 
            onClick={handlePrevPage} 
            disabled={currentPage === 1} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            Prev
          </button>
          {firstTen.map(page => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={`px-2 py-1 rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}
            >
              {page}
            </button>
          ))}
          <span className="px-2 py-1 text-white">...</span>
          {lastTen.map(page => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={`px-2 py-1 rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}
            >
              {page}
            </button>
          ))}
          <button 
            onClick={handleNextPage} 
            disabled={currentPage === totalPages} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            Next
          </button>
          <button 
            onClick={handleLastPage} 
            disabled={currentPage === totalPages} 
            className="px-2 py-1 bg-gray-600 text-white rounded disabled:opacity-50"
          >
            Last
          </button>
        </div>
      );
    }
  };

  return (
    <div className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px] text-white">
      {/* Search */}
      <div className="w-full sm:w-[50%] flex flex-col gap-3">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by username or userId"
          className="bg-[#4b4b4b] w-full placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
        />
        <div className="flex gap-2">
          <button 
            onClick={handleSearch} 
            className="bg-[#17B502] font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#000]"
          >
            Search
          </button>
          {isSearchMode && (
            <button
              onClick={handleClearSearch}
              className="bg-gray-500 font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#fff]"
            >
              Clear Search
            </button>
          )}
        </div>
      </div>

      {/* Messages */}
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {successMessage && <p className="text-green-500">{successMessage}</p>}

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-3 items-start">
        <select value={levelFilter} onChange={(e) => setLevelFilter(e.target.value)} className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2">
          <option value="">Level (All)</option>
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Advanced">Advanced</option>
        </select>
        <div className="flex gap-2">
          <input
            type="number"
            placeholder="PPH From"
            value={pphFrom}
            onChange={(e) => setPphFrom(e.target.value)}
            className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2 w-[100px]"
          />
          <input
            type="number"
            placeholder="PPH To"
            value={pphTo}
            onChange={(e) => setPphTo(e.target.value)}
            className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2 w-[100px]"
          />
        </div>
        <div className="flex gap-2">
          <input
            type="number"
            placeholder="Tokens From"
            value={tokenFrom}
            onChange={(e) => setTokenFrom(e.target.value)}
            className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2 w-[100px]"
          />
          <input
            type="number"
            placeholder="Tokens To"
            value={tokenTo}
            onChange={(e) => setTokenTo(e.target.value)}
            className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2 w-[100px]"
          />
        </div>
        <select value={lastLoginFilter} onChange={(e) => setLastLoginFilter(e.target.value)} className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2">
          <option value="">Last Login (All)</option>
          <option value="today">Today</option>
          <option value="lastWeek">Last 7 Days</option>
          <option value="lastMonth">Last 30 Days</option>
        </select>
      </div>

      {/* Users Table */}
      <div className="w-full overflow-auto">
        <table className="min-w-full bg-[#4b4b4b] text-[13px] rounded-[10px]">
          <thead className="bg-[#3a3a3a]">
            <tr>
            <th className="py-2 px-4 text-left cursor-pointer" onClick={() => handleSort('id')}>
                UserID {sortField === 'id' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
            
              <th className="py-2 px-4 text-left cursor-pointer" onClick={() => handleSort('username')}>
                Username {sortField === 'username' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              
              <th className="py-2 px-4 text-left cursor-pointer" onClick={() => handleSort('balance')}>
                Balance {sortField === 'balance' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th className="py-2 px-4 text-left cursor-pointer" onClick={() => handleSort('profitHour')}>
                PPH {sortField === 'profitHour' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th className="py-2 px-4 text-left cursor-pointer" onClick={() => handleSort('lastLogin')}>
                Last Login {sortField === 'lastLogin' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th className="py-2 px-4 text-left cursor-pointer" onClick={() => handleSort('level')}>
                Level {sortField === 'level' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th className="py-2 px-4 text-left">Actions</th>
              
            </tr>
             
          </thead>
          <tbody>
            {filteredUsers.map((u) => (
              <tr key={u.id} className="hover:bg-[#3a3a3a]">
                <td className="py-2 px-4">{u.id}</td>
                
                <td className="py-2 px-4">{u.username || 'N/A'}</td>
                
                <td className="py-2 px-4">{formatNumber(u.balance)}</td>
                <td className="py-2 px-4">{u.profitHour || 0}</td>
                <td className="py-2 px-4">{u.lastLogin ? new Date(u.lastLogin).toLocaleString() : 'N/A'}</td>
                <td className="py-2 px-4">{u.level && u.level.name ? u.level.name : 'N/A'}</td>
                <td className="py-2 px-4">
                  <button
                    onClick={() => {
                      setUser(u);
                      setEditUserData(u);
                      setIsEditing(true);
                    }}
                    className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteUser(u.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete
                  </button>
                </td>
                
              </tr>
            ))}
            {filteredUsers.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center py-4">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {renderPagination()}

      {loading && <p className="text-center mt-2">Loading...</p>}

      {/* Edit User Modal */}
      {isEditing && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <div className="bg-white p-4 rounded shadow-lg text-black w-full max-w-sm">
            <h2 className="text-xl font-bold mb-4">Edit User</h2>
            <input
              type="number"
              name="balance"
              value={editUserData.balance || ''}
              onChange={(e) => setEditUserData({...editUserData, balance: Number(e.target.value)})}
              placeholder="Balance"
              className="border p-2 w-full mb-2"
            />
            <input
              type="number"
              name="tapBalance"
              value={editUserData.tapBalance || ''}
              onChange={(e) => setEditUserData({...editUserData, tapBalance: Number(e.target.value)})}
              placeholder="Tap Balance"
              className="border p-2 w-full mb-2"
            />
            <button
              onClick={handleUpdateUser}
              className="bg-green-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagementPanel;
