import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../context/userContext';

const Airdrop = () => {
  return (
    <>
      <div style={{overflow: "hidden", height: "100vh", position: "relative"}}>
        <img src="/gofm-popup-image-min2.png"
            style={{
              width: "100%",
              position: "relative"
            }}
        />

        <div className="overflow-y-auto" style={{opacity: "0.85", position: "absolute", bottom: "0px", left: "0px",width: "100%", boxSizing: "border-box",padding: "30px",textAlign: "center", backgroundColor: "#030303", paddingBottom: "130px"}}>
          <b>Play Guardians Of Finger Monkeys:</b><br /><br />
          <img src="/diamond.svg" alt="congrats" style={{display:"inline-block"}} className="w-[16px]" /> Play and grow your Levels & XPs<br />
          <img src="/diamond.svg" alt="congrats" style={{display:"inline-block"}} className="w-[16px]" />  Collect XP from activities section<br />
          <img src="/diamond.svg" alt="congrats" style={{display:"inline-block"}} className="w-[16px]" /> Refer your friends to earn more XP<br /><br />
          <b>What do you gain?</b><br /><br />
          <img src="/gift2.svg" alt="congrats" style={{display:"inline-block"}} className="w-[21px]" />  Earn FREE $FMT Tokens in our Airdrop<br /><br />
          <img src="/land.png" alt="congrats" style={{display:"inline-block"}} className="w-[28px]" />  Unlock LAND discounts the higher your level, the bigger your discount!

        </div>
       
      </div>
    </>
  );
};

export default Airdrop;
