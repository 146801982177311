// MessageSender.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { collection, getDocs } from "firebase/firestore";

const TELEGRAM_API_KEY = "7904324480:AAFG8zQ_j6iwfkx0aNJFDPpZQaP_ywbupts";
const url = `https://api.telegram.org/bot${TELEGRAM_API_KEY}/sendMessage`;

const MessageSender = () => {
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [sentUsers, setSentUsers] = useState(0);
  const [isSending, setIsSending] = useState(false);

  // Get the list of user IDs (document IDs from telegramUsers) from Firebase
  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "telegramUsers"));
      const users = querySnapshot.docs.map((doc) => doc.id); // Access the document ID as the user ID
      setTotalUsers(users.length);
    };
    fetchUsers();
  }, []);

  const sendMessageToUsers = async () => {
    if (message.trim() === "") return;

    setIsSending(true);
    setProgress(0);
    setSentUsers(0);

    const querySnapshot = await getDocs(collection(db, "telegramUsers"));
    const users = querySnapshot.docs.map((doc) => doc.id); // Use doc.id as the telegramUserId
    console.log(users);
    
    const total = users.length;

    // Send message to all users
    for (let i = 0; i < total; i++) {
      const chatId = users[i]; // This should be the document ID (user ID)
      console.log(`Sending message to user with chat_id: ${chatId}`);  // Log the chat_id

      try {
        /*
        await axios.post(url, {
          chat_id: chatId,  // Use the document ID (which is the user ID)
          text: message,
        });
        setSentUsers(i + 1);
        setProgress(Math.floor(((i + 1) / total) * 100));
        */
      } catch (error) {
        console.error(`Error sending message to user with chat_id ${chatId}:`, error.response?.data || error);
      }
    }
    
    setIsSending(false);
  };

  return (
    <div>
      <h1>Send Message to All Users</h1>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here"
        rows="10"
        style={{ width: "100%", color: "black" }}
      />
      <div>
        <button onClick={sendMessageToUsers} disabled={isSending}>
          {isSending ? "Sending..." : "Send Message"}
        </button>
      </div>

      {isSending && (
        <div>
          <h3>Progress: {sentUsers}/{totalUsers}</h3>
          <progress value={progress} max="100" style={{ width: "100%" }} />
          <p>Estimated Time: {Math.ceil((totalUsers - sentUsers) / 10)} minutes</p>
        </div>
      )}
    </div>
  );
};

export default MessageSender;