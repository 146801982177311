import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../context/userContext';
import { RiArrowRightSLine } from 'react-icons/ri';
import { RiSettings4Fill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';

// modals
import Levels from '../Components/Levels';
import SettingsMenu from '../Components/SettingsMenu';
import Exchanges from '../Components/Exchanges';
import BalanceInfo from '../Components/BalanceInfo';
import Popup from '../Components/Popup';

const userLevels = [
  { id: 1, name: 'Tamaq', icon: '/level1.png', tapBalanceRequired: 50000 },          // 50K tap balance
  { id: 2, name: 'Oranu', icon: '/level2.png', tapBalanceRequired: 500000 },         // 500K tap balance
  { id: 3, name: 'Xaruk', icon: '/level3.png', tapBalanceRequired: 1000000 },        // 1M tap balance
  { id: 4, name: 'Maloko', icon: '/level4.png', tapBalanceRequired: 5000000 },        // 5M tap balance
  { id: 5, name: 'Venaru', icon: '/level5.png', tapBalanceRequired: 20000000 },       // 20M tap balance
  { id: 6, name: 'Rokama', icon: '/level6.png', tapBalanceRequired: 50000000 },       // 50M tap balance
  { id: 7, name: 'Xatar', icon: '/level7.png', tapBalanceRequired: 200000000 },      // 200M tap balance
  { id: 8, name: 'Kulvano', icon: '/level8.png', tapBalanceRequired: 500000000 },      // 500M tap balance
  { id: 9, name: 'Lorak', icon: '/level9.png', tapBalanceRequired: 1000000000 },     // 1B tap balance
  { id: 10, name: 'Thamoru', icon: '/level10.png', tapBalanceRequired: 10000000000 }  // 10B tap balance
];



const MiniGames = () => {
  const { fullName, tapBalance, refBonus, balance, selectedCharacter, selectedExchange } = useUser();

  const [showExchange, setShowExchange] = useState(false);
  const [info, setInfo] = useState(false);
  const [showLevel, setShowLevel] = useState(false);
  const initialLevelIndex = userLevels.findIndex((level) => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;
  const displayedLevelIndex = currentLevelIndex;
  const currentLevel = userLevels[displayedLevelIndex];
  const [showSetting, setShowSetting] = useState(false);




  const formatNumberCliam = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
    } else {
      return (num / 1000000).toFixed(2).replace('.', '.') + ' M';
    }
  };
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.stopPropagation(); // Allow scroll within this element on touch devices
    };

    const scrollElement = scrollRef.current;

    if (scrollElement) {
      scrollElement.addEventListener('touchmove', handleTouchMove, { passive: true });
    }

    // Clean up the event listener on component unmount
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  return (
    <>
     <div
        className="overflow-y-auto h-screen scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-800"
        ref={scrollRef}
        style={{ marginTop: '-12px' }}
      >
        {/* HEADER START */}
        <div className="flex justify-between bg-[#1a1a1a] p-4 items-center bg-gray-800 bg-opacity-50 p-3 rounded-bl-[0px] rounded-br-[0px] shadow-lg" style={{backgroundColor:"#161210"}}>
          {/* LEFT */}
          <div>
            {/* User Info */}
            <div className="flex items-center space-x-3">
              <div className="relative flex items-center justify-center overflow-hidden">
                <img
                  src={selectedCharacter.avatar}
                  className="w-12 h-12 rounded-full border-4 border-yellow-400 shadow-lg"
                  alt={fullName || 'user'}
                />
                <span className="absolute bottom-0 right-0 bg-green-500 w-4 h-4 rounded-full border-2 border-white"></span>
              </div>
              <div>
                <h1 className="text-lg font-bold text-white">{fullName}</h1>
                <span className="text-sm text-yellow-400 font-semibold">FM Guardian</span>
              </div>
            </div>
            {/* User Level */}
            <div style={{ margin: '10px 0 0 0' }}>
              {/* Progress Bar */}
              <div className="w-full bg-gray-700 rounded-full h-6 shadow-inner overflow-hidden" style={{backgroundColor: "#241E1D"}}>
                <div
                  className="bg-gradient-to-r from-green-400 to-blue-500 h-full rounded-full"
                  style={{ width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%` }}
                />
              </div>

              {/* Level Information */}
              <div
                className="flex items-center justify-between mt-4"
                style={{
                  marginTop: '-26px',
                  zIndex: 3,
                  position: 'relative',
                  transform: 'scale(0.7)',
                }}
              >
                <span
                  onClick={() => setShowLevel(true)}
                  className="text-white cursor-pointer font-bold text-xl flex items-center hover:underline"
                >
                  Level {currentLevel.id}: {currentLevel.name}
                  <RiArrowRightSLine size={20} className="ml-2 text-white-400" />
                </span>
                <span className="text-gray-400 text-sm">
                  ({currentLevel.id}/{userLevels.length})
                </span>
              </div>
            </div>
          </div>
          {/* RIGHT */}
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowExchange(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              style={{backgroundColor:"#010101"}}>
                <img
                  id={selectedExchange.id}
                  src={selectedExchange.icon}
                  alt={selectedExchange.name}
                  className="w-6 h-6"
                />
              </button>
              <button
                onClick={() => setShowSetting(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              style={{backgroundColor:"#010101"}}>
                <RiSettings4Fill size={24} className="text-white" />
              </button>
            </div>
            <div
              className="w-fit py-[2px] px-3 flex items-center space-x-1 justify-center border-[1px] border-[#241E1D] rounded-[25px]"
              style={{ marginTop: '18px' }}
            >
              <span className="w-[14px]">
                <img alt="engy" src="/loader.webp" className="w-full" />
              </span>
              <h1 className="text-[15px] text-[#75FF9E] font-bold">{formatNumberCliam(balance + refBonus)}</h1>
            </div>
          </div>
        </div>
        {/* HEADER END */}

        <h1 className="text-center text-[24px] mt-[18px]">Mini Games</h1>
        <NavLink
        to="/events"
        className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-3xl mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
        style={{
          width: '90%',
          display: 'block',
          margin:"0 auto",
          textAlign: 'center',
        }}
      >
        Check our Reward events
      </NavLink>
        <div className=" scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-800 p-4 rounded-lg shadow-lg">



        <div className="grid grid-cols-1 gap-6">
      {/* Card 1: Auto Mining */}
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/auto-mining.png" // Replace with your actual image URL
          alt="Auto Mining Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">Auto Mining</h1>
          <p className="text-gray-400 text-sm mt-2" style={{color: "#B9ACA9"}}>
            Run it and make XP's Offline
          </p>
          <NavLink
            to="/farm"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
         >
            Play
          </NavLink>
        </div>
      </div>

      {/* Card 2: Spin */}
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/spin.png" // Replace with your actual image URL
          alt="Spin Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">Spin</h1>
          <p className="text-gray-400 text-sm mt-2"  style={{color: "#B9ACA9"}}>
            Try your luck and collect more XP's
          </p>
          <NavLink
            to="/roulette"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
        >
            Play
          </NavLink>
        </div>
      </div>

      {/* Card 3: FatMonkey */}
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/fat-monkey.png" // Replace with your actual image URL
          alt="FatMonkey Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">FatMonkey</h1>
          <p className="text-gray-400 text-sm mt-2"  style={{color: "#B9ACA9"}}>
            Go to the moon for more XP's
          </p>
          <NavLink
            to="/FatBoy"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
         >
            Play
          </NavLink>
        </div>
      </div>

      {/* Card 4: Jungle Invaders */}
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/jungle-invaders.png" // Replace with your actual image URL
          alt="Jungle Invaders Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">Jungle Invaders</h1>
          <p className="text-gray-400 text-sm mt-2" style={{color: "#B9ACA9"}}>
            Strike invaders to win more
          </p>
          <NavLink
            to="/aliens"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
          >
            Play
          </NavLink>
        </div>
      </div>
      {/* Card 4: Banana Frenzy */}
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/banana-frenzy.png" // Replace with your actual image URL
          alt="Banana Frenzy Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">Banana Frenzy</h1>
          <p className="text-gray-400 text-sm mt-2" style={{color: "#B9ACA9"}}>
            Strike invaders to win more
          </p>
          <NavLink
            to="/bananafrenzy"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
          >
            Play
          </NavLink>
        </div>
      </div>

      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/tropic-blaster.png" // Replace with your actual image URL
          alt="Banana Frenzy Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">Tropic Blaster</h1>
          <p className="text-gray-400 text-sm mt-2" style={{color: "#B9ACA9"}}>
          Blast those bubbles for XP's
          </p>
          <NavLink
            to="/tropic-blaster"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
          >
            Play
          </NavLink>
        </div>
      </div>
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg" style={{backgroundColor: "#0F0F0F"}}>
        {/* Image Placeholder */}
        <img
          className="w-full h-48 object-cover rounded-t-lg"
          src="/game_images/jungle-loops.png" // Replace with your actual image URL
          alt="Banana Frenzy Game Screenshot"
        />
        <div className="mt-4">
          <h1 className="text-white text-lg font-bold">Jungle loops</h1>
          <p className="text-gray-400 text-sm mt-2" style={{color: "#B9ACA9"}}>
          Blast those bubbles for XP's
          </p>
          <NavLink
            to="/hexa-game"
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-base font-semibold rounded-3xl mt-4 p-2 w-full text-center transform hover:scale-105 transition duration-300"
            style={{display:"block"}}
          >
            Play
          </NavLink>
        </div>
      </div>

    
   {/** 
    <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg">
      <h1 className="text-white text-[18px] font-bold">Super Mining</h1>
      <p className="text-gray-400 text-[14px] mt-2">Catch as much rewards</p>
      <NavLink
        to="/moonbix"
        className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-lg mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
        style={{
          width: '100%',
          display: 'block',
          textAlign: 'center',
        }}
      >
        Play
      </NavLink>
    </div>
 
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg">
      <h1 className="text-white text-[18px] font-bold">Sling Safari</h1>
      <p className="text-gray-400 text-[14px] mt-2">Swing through the jungle for XP's</p>
      <NavLink
        to="/Sling"
        className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-lg mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
        style={{
          width: '100%',
          display: 'block',
          textAlign: 'center',
        }}
      >
        Play
      </NavLink>
    </div>
    <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg">
      <h1 className="text-white text-[18px] font-bold">Tropic Blaster</h1>
      <p className="text-gray-400 text-[14px] mt-2">Blast those bubbles for XP's</p>
      <NavLink
        to="/tropic-blaster"
        className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-lg mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
        style={{
          width: '100%',
          display: 'block',
          textAlign: 'center',
        }}
      >
        Play
      </NavLink>
    </div>
    */}
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</div>

      </div>

      {/* MODALS */}
      <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
      <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
      <SettingsMenu showSetting={showSetting} setShowSetting={setShowSetting} />
      <BalanceInfo info={info} setInfo={setInfo} />

      
        <Popup  />
      
    </>
  );
};

export default MiniGames;
