import React, { createContext, useContext, useState, useEffect, useCallback,useRef } from 'react';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDocs,
  collection,
  orderBy,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const userLevelss = [
  { id: 1, name: 'Tamaq', icon: '/level1.png', tapBalanceRequired: 50000 },          // 50K tap balance
  { id: 2, name: 'Oranu', icon: '/level2.png', tapBalanceRequired: 500000 },         // 500K tap balance
  { id: 3, name: 'Xaruk', icon: '/level3.png', tapBalanceRequired: 1000000 },        // 1M tap balance
  { id: 4, name: 'Maloko', icon: '/level4.png', tapBalanceRequired: 5000000 },        // 5M tap balance
  { id: 5, name: 'Venaru', icon: '/level5.png', tapBalanceRequired: 20000000 },       // 20M tap balance
  { id: 6, name: 'Rokama', icon: '/level6.png', tapBalanceRequired: 50000000 },       // 50M tap balance
  { id: 7, name: 'Xatar', icon: '/level7.png', tapBalanceRequired: 200000000 },      // 200M tap balance
  { id: 8, name: 'Kulvano', icon: '/level8.png', tapBalanceRequired: 500000000 },      // 500M tap balance
  { id: 9, name: 'Lorak', icon: '/level9.png', tapBalanceRequired: 1000000000 },     // 1B tap balance
  { id: 10, name: 'Thamoru', icon: '/level10.png', tapBalanceRequired: 10000000000 }  // 10B tap balance
];

const prTeam = [
  {
    title: 'Zokari',
    level: [
      { level: 1, cost: 1000, profit: 10 },
      { level: 2, cost: 3000, profit: 28.5 },
      { level: 3, cost: 5000, profit: 45 },
      { level: 4, cost: 7000, profit: 59.6 },
      { level: 5, cost: 9000, profit: 72 },
      { level: 6, cost: 11000, profit: 82.6 },
      { level: 7, cost: 33000, profit: 93 },
      { level: 8, cost: 55000, profit: 203.6 },
      { level: 9, cost: 117000, profit: 314 },
      { level: 10, cost: 159000, profit: 424.6 },
      { level: 11, cost: 161000, profit: 735 },
      { level: 12, cost: 183000, profit: 1045.6 },
      { level: 13, cost: 205000, profit: 1356 },
      { level: 14, cost: 22700, profit: 1666.6 },
      { level: 15, cost: 249000, profit: 1977 },
    ],
    totalProfit: 0,
    icon: '/gym.png',
    description:
      'Start training at the Zokari.',
  },
  {
    title: 'Dralok',
    level: [
      { level: 1, profit: 18, cost: 1900 },
      { level: 2, profit: 34, cost: 3500 },
      { level: 3, profit: 50, cost: 5100 },
      { level: 4, profit: 66, cost: 6700 },
      { level: 5, profit: 82, cost: 8300 },
      { level: 6, profit: 98, cost: 9900 },
      { level: 7, profit: 154, cost: 11500 },
      { level: 8, profit: 210, cost: 13100 },
      { level: 9, profit: 266, cost: 14700 },
      { level: 10, profit: 522, cost: 36300 },
      { level: 11, profit: 778, cost: 77900 },
      { level: 12, profit: 1034, cost: 139500 },
      { level: 13, profit: 1290, cost: 221000 },
      { level: 14, profit: 1546, cost: 422700 },
      { level: 15, profit: 1802, cost: 624300 },
    ],
    totalProfit: 0,
    icon: '/sparring.png',
    description:
      'Learn the skills by Dralok with a friend.',
  },
  {
    title: 'Vikoro',
    level: [
      { level: 1, profit: 33, cost: 3400 },
      { level: 2, profit: 56, cost: 4700 },
      { level: 3, profit: 79, cost: 6000 },
      { level: 4, profit: 102, cost: 7300 },
      { level: 5, profit: 125, cost: 8600 },
      { level: 6, profit: 148, cost: 9900 },
      { level: 7, profit: 171, cost: 11200 },
      { level: 8, profit: 194, cost: 12500 },
      { level: 9, profit: 417, cost: 33800 },
      { level: 10, profit: 640, cost: 75100 },
      { level: 11, profit: 863, cost: 156400 },
      { level: 12, profit: 1086, cost: 217700 },
      { level: 13, profit: 1309, cost: 559000 },
      { level: 14, profit: 1532, cost: 620300 },
      { level: 15, profit: 1755, cost: 1021600 },
    ],
    totalProfit: 0,
    icon: '/jiujitsu.png',
    description:
      'Overwhelm your opponents with advanced Vikoro.',
  },
  {
    title: 'Xoratu',
    level: [
      { level: 1, profit: 48, cost: 7000 },
      { level: 2, profit: 80, cost: 10000 },
      { level: 3, profit: 112, cost: 13000 },
      { level: 4, profit: 144, cost: 16000 },
      { level: 5, profit: 176, cost: 19000 },
      { level: 6, profit: 208, cost: 22000 },
      { level: 7, profit: 440, cost: 25000 },
      { level: 8, profit: 672, cost: 108000 },
      { level: 9, profit: 904, cost: 231000 },
      { level: 10, profit: 1136, cost: 634000 },
      { level: 11, profit: 1368, cost: 1037000 },
      { level: 12, profit: 1600, cost: 1440000 },
      { level: 13, profit: 1832, cost: 1643000 },
      { level: 14, profit: 2064, cost: 2046000 },
      { level: 15, profit: 2296, cost: 2449000 },
    ],
    totalProfit: 0,
    icon: '/Taekwondo.png',
    description:
      'Win with fancy Xoratu',
  },
  {
    title: 'Tikar',
    level: [
      { level: 1, profit: 96, cost: 13400 },
      { level: 2, profit: 118, cost: 16600 },
      { level: 3, profit: 140, cost: 19800 },
      { level: 4, profit: 162, cost: 23000 },
      { level: 5, profit: 184, cost: 66200 },
      { level: 6, profit: 206, cost: 169400 },
      { level: 7, profit: 228, cost: 432600 },
      { level: 8, profit: 650, cost: 517900 },
      { level: 9, profit: 1072, cost: 1035800 },
      { level: 10, profit: 1494, cost: 2042200 },
      { level: 11, profit: 1916, cost: 3045400 },
      { level: 12, profit: 2338, cost: 3448600 },
      { level: 13, profit: 2760, cost: 5051800 },
      { level: 14, profit: 3182, cost: 5455000 },
      { level: 15, profit: 3604, cost: 6658200 },
    ],
    totalProfit: 0,
    icon: '/karate.png',
    description:
      'Learn new martial arts and become a Tikar.',
  },
  {
    title: 'Lunaro',
    level: [
      { level: 1, profit: 116, cost: 17000 },
      { level: 2, profit: 134, cost: 19400 },
      { level: 3, profit: 152, cost: 21800 },
      { level: 4, profit: 170, cost: 24200 },
      { level: 5, profit: 188, cost: 26600 },
      { level: 6, profit: 206, cost: 209000 },
      { level: 7, profit: 624, cost: 631400 },
      { level: 8, profit: 1042, cost: 1033800 },
      { level: 9, profit: 1460, cost: 1636200 },
      { level: 10, profit: 1878, cost: 2638600 },
      { level: 11, profit: 2296, cost: 4441000 },
      { level: 12, profit: 2714, cost: 6243400 },
      { level: 13, profit: 3132, cost: 7645800 },
      { level: 14, profit: 3550, cost: 8048200 },
      { level: 15, profit: 3968, cost: 9050600 },
    ],
    totalProfit: 0,
    icon: '/MuayThai.png',
    description:
      'Show off your skills with more flashy martial arts..',
  },
  {
    title: 'Zaraku',
    level: [
      { level: 1, profit: 140, cost: 21600 },
      { level: 2, profit: 166, cost: 25200 },
      { level: 3, profit: 192, cost: 48800 },
      { level: 4, profit: 218, cost: 72400 },
      { level: 5, profit: 244, cost: 176000 },
      { level: 6, profit: 496, cost: 319600 },
      { level: 7, profit: 870, cost: 643200 },
      { level: 8, profit: 1122, cost: 1646800 },
      { level: 9, profit: 1748, cost: 2450400 },
      { level: 10, profit: 2374, cost: 5454000 },
      { level: 11, profit: 3000, cost: 7057600 },
      { level: 12, profit: 4426, cost: 11061200 },
      { level: 13, profit: 5852, cost: 12664800 },
      { level: 14, profit: 7278, cost: 13468400 },
      { level: 15, profit: 8704, cost: 14072000 },
    ],
    totalProfit: 0,
    icon: '/MMA.png',
    description:
      'Become the strongest in ground martial arts.',
  },

  {
    title: 'Ohrak',
    level: [
      { level: 1, profit: 180, cost: 31400 },
      { level: 2, profit: 260, cost: 46000 },
      { level: 3, profit: 340, cost: 100600 },
      { level: 4, profit: 420, cost: 169200 },
      { level: 5, profit: 1300, cost: 489800 },
      { level: 6, profit: 2180, cost: 704400 },
      { level: 7, profit: 3060, cost: 1319000 },
      { level: 8, profit: 3940, cost: 2133600 },
      { level: 9, profit: 8820, cost: 3548200 },
      { level: 10, profit: 13700, cost: 5362800 },
      { level: 11, profit: 18580, cost: 7777400 },
      { level: 12, profit: 23460, cost: 10592000 },
      { level: 13, profit: 28340, cost: 12206600 },
      { level: 14, profit: 33220, cost: 14221200 },
      { level: 15, profit: 38100, cost: 16235800 },
    ],
    totalProfit: 0,
    icon: '/kungfu.png',
    description:
      'Overwhelm your enemies with powerful Ohrak.',
  },
];


const marketTeam = [
  {
    title: 'Beaker',
    level: [
      { level: 1, profit: 190, cost: 36000 },
      { level: 2, profit: 360, cost: 79000 },
      { level: 3, profit: 530, cost: 122000 },
      { level: 4, profit: 700, cost: 165000 },
      { level: 5, profit: 870, cost: 208000 },
      { level: 6, profit: 1040, cost: 251000 },
      { level: 7, profit: 1210, cost: 294000 },
      { level: 8, profit: 1380, cost: 537000 },
      { level: 9, profit: 1550, cost: 1180000 },
      { level: 10, profit: 4400, cost: 4223000 },
      { level: 11, profit: 7250, cost: 6466000 },
      { level: 12, profit: 10100, cost: 10509000 },
      { level: 13, profit: 12950, cost: 12552000 },
      { level: 14, profit: 15800, cost: 16595000 },
      { level: 15, profit: 18650, cost: 18638000 },
    ],
    totalProfit: 0,
    icon: '/localgang.png',
    description:
      'Challenge  Beaker.',
  },
  {
    title: 'Tyrnox',
    level: [
      { level: 1, profit: 460, cost: 119000 },
      { level: 2, profit: 680, cost: 166000 },
      { level: 3, profit: 900, cost: 222000 },
      { level: 4, profit: 1120, cost: 278000 },
      { level: 5, profit: 1340, cost: 734000 },
      { level: 6, profit: 1560, cost: 1190000 },
      { level: 7, profit: 1780, cost: 1446000 },
      { level: 8, profit: 2000, cost: 1702000 },
      { level: 9, profit: 2220, cost: 1958000 },
      { level: 10, profit: 6440, cost: 6214000 },
      { level: 11, profit: 10660, cost: 10470000 },
      { level: 12, profit: 12880, cost: 10720000 },
      { level: 13, profit: 15100, cost: 10982000 },
      { level: 14, profit: 17320, cost: 12038000 },
      { level: 15, profit: 19540, cost: 13494000 },
    ],
    totalProfit: 0,
    icon: '/country_boss.png',
    description:
      'A fateful encounter with the Tyrnox.',
  },
  {
    title: 'Banzul',
    level: [
      { level: 1, profit: 580, cost: 153800 },
      { level: 2, profit: 900, cost: 300000 },
      { level: 3, profit: 1220, cost: 446200 },
      { level: 4, profit: 1540, cost: 592400 },
      { level: 5, profit: 1860, cost: 1138600 },
      { level: 6, profit: 2180, cost: 1684800 },
      { level: 7, profit: 3700, cost: 3031000 },
      { level: 8, profit: 5220, cost: 7177200 },
      { level: 9, profit: 6740, cost: 7323400 },
      { level: 10, profit: 8260, cost: 7469600 },
      { level: 11, profit: 9780, cost: 9615800 },
      { level: 12, profit: 11300, cost: 110162000 },
      { level: 13, profit: 12820, cost: 11148200 },
      { level: 14, profit: 14340, cost: 12054400 },
      { level: 15, profit: 15860, cost: 12200600 },
    ],
    totalProfit: 0,
    icon: '/hiddenexpert.png',
    description:
      'Challenge the veiled Banzul.',
  },
  {
    title: 'Leapfang',
    level: [
      { level: 1, profit: 480, cost: 166000 },
      { level: 2, profit: 1400, cost: 480000 },
      { level: 3, profit: 2320, cost: 794000 },
      { level: 4, profit: 3240, cost: 1108000 },
      { level: 5, profit: 4160, cost: 1422000 },
      { level: 6, profit: 5080, cost: 3736000 },
      { level: 7, profit: 8000, cost: 6050000 },
      { level: 8, profit: 10920, cost: 6364000 },
      { level: 9, profit: 13840, cost: 8678000 },
      { level: 10, profit: 14760, cost: 1099200 },
      { level: 11, profit: 15680, cost: 11306000 },
      { level: 12, profit: 16600, cost: 12020000 },
      { level: 13, profit: 17520, cost: 12134000 },
      { level: 14, profit: 18440, cost: 15448000 },
      { level: 15, profit: 19360, cost: 16562000 },
    ],
    totalProfit: 0,
    icon: '/former.png',
    description:
      'Stand up to the powerful punches of the Leapfang.',
  },
  {
    title: 'Zimark',
    level: [
      { level: 1, profit: 650, cost: 250000 },
      { level: 2, profit: 1600, cost: 740000 },
      { level: 3, profit: 2550, cost: 1230000 },
      { level: 4, profit: 3500, cost: 1720000 },
      { level: 5, profit: 4450, cost: 2210000 },
      { level: 6, profit: 5400, cost: 6700000 },
      { level: 7, profit: 6350, cost: 7190000 },
      { level: 8, profit: 7300, cost: 9680000 },
      { level: 9, profit: 8250, cost: 10170000 },
      { level: 10, profit: 9200, cost: 10660000 },
      { level: 11, profit: 10150, cost: 13150000 },
      { level: 12, profit: 13100, cost: 15640000 },
      { level: 13, profit: 16050, cost: 16330000 },
      { level: 14, profit: 19000, cost: 17220000 },
      { level: 15, profit: 21350, cost: 19110000 },
    ],
    totalProfit: 0,
    icon: '/dark.png',
    description:
      'A fateful bloody battle with the strongest in Zimark.',
  },
  {
    title: 'Aurak',
    level: [
      { level: 1, profit: 1100, cost: 460000 },
      { level: 2, profit: 2200, cost: 1100000 },
      { level: 3, profit: 3300, cost: 1740000 },
      { level: 4, profit: 4400, cost: 2380000 },
      { level: 5, profit: 5500, cost: 3020000 },
      { level: 6, profit: 6600, cost: 3660000 },
      { level: 7, profit: 7700, cost: 4300000 },
      { level: 8, profit: 8800, cost: 6940000 },
      { level: 9, profit: 9900, cost: 9580000 },
      { level: 10, profit: 11000, cost: 10220000 },
      { level: 11, profit: 12100, cost: 10860000 },
      { level: 12, profit: 13200, cost: 13500000 },
      { level: 13, profit: 14300, cost: 14140000 },
      { level: 14, profit: 15400, cost: 14780000 },
      { level: 15, profit: 16500, cost: 15420000 },
    ],
    totalProfit: 0,
    icon: '/gangboss.png',
    description:
      'Defeat Aurak.',
  },
  {
    title: 'Skyflare',
    level: [
      { level: 1, profit: 1460, cost: 720000 },
      { level: 2, profit: 2500, cost: 1240000 },
      { level: 3, profit: 3540, cost: 1760000 },
      { level: 4, profit: 4580, cost: 2280000 },
      { level: 5, profit: 5620, cost: 2800000 },
      { level: 6, profit: 6660, cost: 5320000 },
      { level: 7, profit: 7700, cost: 7840000 },
      { level: 8, profit: 8740, cost: 83600000 },
      { level: 9, profit: 9780, cost: 108800000 },
      { level: 10, profit: 10820, cost: 11400000 },
      { level: 11, profit: 11860, cost: 13920000 },
      { level: 12, profit: 12900, cost: 14440000 },
      { level: 13, profit: 13940, cost: 14960000 },
      { level: 14, profit: 14980, cost: 15480000 },
      { level: 15, profit: 16020, cost: 16000000 },
    ],
    totalProfit: 0,
    icon: '/ffchamp.png',
    description:
      'Endless showdown with war heroes.',
  },
  {
    title: 'Moonwatcher',
    level: [
      { level: 1, profit: 2600, cost: 1158000 },
      { level: 2, profit: 3600, cost: 1774000 },
      { level: 3, profit: 4600, cost: 2390000 },
      { level: 4, profit: 5600, cost: 3006000 },
      { level: 5, profit: 6600, cost: 3622000 },
      { level: 6, profit: 7600, cost: 4238000 },
      { level: 7, profit: 8600, cost: 4854000 },
      { level: 8, profit: 9600, cost: 5470000 },
      { level: 9, profit: 10600, cost: 6086000 },
      { level: 10, profit: 11600, cost: 6702000 },
      { level: 11, profit: 12600, cost: 9318000 },
      { level: 12, profit: 13600, cost: 11934000 },
      { level: 13, profit: 14600, cost: 12550000 },
      { level: 14, profit: 15600, cost: 13166000 },
      { level: 15, profit: 16600, cost: 15782000 },
    ],
    totalProfit: 0,
    icon: '/champ.png',
    description:
      'Win the revenge match against the Moonwatcher',
  },
];






const specialCards = [
  {
    title: 'NORTHFANG',
    profit: 1,
    cost: '500000000',
    icon: '/hunter.webp',
    tagline: 'Win $100k Giveaway',
    description:
      'Enter our 100k USDT Giveaway. 3 lucky FMT guardians who participate, will receive 33,333k USDT worth of FMT tokens. Each ticket gives you more chances to win!',
    class: 'specials1',
  },
  {
    title: 'SOUTHSHADE',
    profit: 2,
    cost: '100000000',
    icon: '/access.webp',
    tagline: 'Win $100k Participation',
    description:
      'Enter our 100k USDT Giveaway. 3 lucky FMT guardians who participate, will receive 33,333k USDT worth of FMT tokens. Each ticket gives you more chances to win!',
    class: 'specials2',
  },
  {
    title: 'EASTBLOOM',
    profit: 3,
    cost: '1000000000',
    icon: '/booster.webp',
    tagline: 'Win $100k Participation',
    description: 'Enter our 100k USDT Giveaway. 3 lucky FMT guardians who participate, will receive 33,333k USDT worth of FMT tokens. Each ticket gives you more chances to win!',
    class: 'specials3',
  },
  {
    title: 'WEATROAR',
    profit: 4,
    cost: '200000000',
    icon: '/swap.webp',
    tagline: 'Win $100k Participation',
    description: 'Enter our 100k USDT Giveaway. 3 lucky FMT guardians who participate, will receive 33,333k USDT worth of FMT tokens. Each ticket gives you more chances to win!',
    class: 'specials4',
  },
];

export const UserProvider = ({ children }) => {
  const [isGameOpened, setIsGameOpened] = useState(false);
  const [balance, setBalance] = useState(0);
  const [tapBalance, setTapBalance] = useState(0);
  const [level, setLevel] = useState({ id: 1, name: 'Tamaq (Level 1)', imgUrl: '/level1.png' });
  const [tapValue, setTapValue] = useState({ level: 1, value: 1 });
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);
  const [energy, setEnergy] = useState(0);
  const [battery, setBattery] = useState({ level: 1, energy: 500 });
  const [initialized, setInitialized] = useState(false);
  const [refBonus, setRefBonus] = useState(0);
  const [manualTasks, setManualTasks] = useState([]);
  const [showBalance, setShowBalance] = useState(true);
  const [userManualTasks, setUserManualTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [claimedMilestones, setClaimedMilestones] = useState([]);
  const [claimedReferralRewards, setClaimedReferralRewards] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState({
    id: 'selectex',
    icon: '/exchange.svg',
    name: 'Select exchange',
  });
  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
  const [tapGuru, setTapGuru] = useState(false);
  const [mainTap, setMainTap] = useState(true);
  const [freeGuru, setFreeGuru] = useState(3);
  const [time, setTime] = useState(22);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [lastTime, setLastTime] = useState(null);
  const [claimExchangePoint, setClaimExchangePoint] = useState(true);
  const [selectedCharacter, setSelectedCharacter] = useState({ name: '', avatar: '' });
  const [characterMenu, setCharacterMenu] = useState(false);
  const [fullName, setFullName] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isAddressSaved, setIsAddressSaved] = useState(false); // State to track if address is saved
  const [coolDownTime, setCoolDownTime] = useState(0);
  const [tappingGuru, setTappingGuru] = useState(0);
  const location = useLocation();
  const [openInfoTwo, setOpenInfoTwo] = useState(true);
  const [openInfoThree, setOpenInfoThree] = useState(true);
  const [lastCheckIn, setLastCheckIn] = useState(null);
  const [checkInDays, setCheckInDays] = useState([]);
  const [error, setError] = useState(null);
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [userLevels, setUserLevels] = useState(prTeam.map(() => 0)); // Start at level 0
  const [userLevelsMarket, setUserLevelsMarket] = useState(marketTeam.map(() => 0)); // Start at level 0
  const [totalProfit, setTotalProfit] = useState([0, 0, 0, 0, 0, 0]);
  const [totalMarketProfit, setTotalMarketProfit] = useState([0, 0, 0, 0, 0, 0]);
  const [success, setSuccess] = useState(false);
  const [profitHour, setProfitHour] = useState(0);
  const [purchasedCards, setPurchasedCards] = useState([]);
  const [totalCardsProfits, setTotalCardsProfits] = useState(0);
  const [withdrawals, setWithdrawals] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [youtubeTasks, setYoutubeTasks] = useState([]);
  const [userYoutubeTasks, setUserYoutubeTasks] = useState([]);
  // KM
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [activeUserRank, setActiveUserRank] = useState(null);

  // FARM KM:
  const [miningPower, setMiningPower] = useState(0);
  const [miningTotal, setMiningTotal] = useState(0);

  const assets = [
    { symbol: 'XP', name: 'GOFM', balance: balance, icon: '/GOFM.webp', price: 0.0004348 },
    { symbol: 'USDT', name: 'Tether US', balance: 0, icon: '/tether.webp', price: 1 },
    { symbol: 'TON', name: 'Toncoin', balance: 0, icon: '/ton.png', price: 6.68 },
    { symbol: 'NOT', name: 'Notcoin', balance: 0, icon: '/notcoin_wallet.jpg', price: 0.01075 },
    { symbol: 'BNB', name: 'BNB', balance: 0, icon: '/binance_wallet.webp', price: 562.36 },
    { symbol: 'SOL', name: 'Solana', balance: 0, icon: '/solana_wallet.png', price: 143.34 },
  ];

  const spinnerLimit = 10;

  const [walletAssets, setWalletAssets] = useState(assets);

  const [spinLimit, setSpinLimit] = useState(spinnerLimit); // New state for spin limit

  

  // Declare the ref at the top level of your component
const miningAccumulator = useRef(null); // Initialize it to null or the initial value

// Live mining power update (accumulate changes locally with a ref)
useEffect(() => {
  // Initialize the ref with the current mining total if it's null
  if (miningAccumulator.current === null) {
    miningAccumulator.current = miningTotal;
  }

  const interval = setInterval(() => {
    miningAccumulator.current += miningPower / 3600; // Adds mining power every second
    setMiningTotal(miningAccumulator.current); // Update state for re-rendering
  }, 1000);

  return () => clearInterval(interval);
}, [miningPower]);

// Offline mining and database update (batch updates)
useEffect(() => {
  const handleOfflineMining = async () => {
    if (!id) return;

    const userRef = doc(db, 'telegramUsers', id);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastActive = userData.lastActive?.toDate();
      const now = new Date();

      const timeDifference = (now - lastActive) / 1000; // Time in seconds

      if (timeDifference > 0) {
        const offlineMiningTotal = (miningPower * timeDifference) / 3600;
        const updatedMiningTotal = miningTotal + offlineMiningTotal;

        setMiningTotal(updatedMiningTotal); // Update the local mining total
        localStorage.setItem('lastActive', now.toISOString()); // Store last active time locally
      }
    }
  };

  handleOfflineMining();

  // Batch update to Firestore every 5 minutes
  const updateInterval = setInterval(async () => {
    if (!id) return;
    const userRef = doc(db, 'telegramUsers', id);
    const miningTotalLocal = miningTotal;

    await updateDoc(userRef, {
      miningTotal: miningTotalLocal,
      lastActive: new Date(),
    });

    console.log('Firestore updated with miningTotal:', miningTotalLocal);
  }, 300000); // Every 5 minutes

  return () => clearInterval(updateInterval);
}, [id, miningPower]);


  const startTimer = useCallback(() => {
    setTime(22); // Set the timer to 22 seconds
    setTapGuru(true); // Activate tapGuru mode
    setMainTap(false); // Disable main tapping during tapGuru mode
    setIsTimerRunning(true); // Mark the timer as running
  
    // Set a timeout to stop tapGuru after 22 seconds
    const timeout = setTimeout(() => {
      setTapGuru(false); // Deactivate tapGuru mode
      setMainTap(true); // Re-enable normal tapping after tapGuru ends
      setIsTimerRunning(false); // Stop the timer
    }, 22000); // 22000ms = 22 seconds
  
    return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
  }, []);
  

  const fetchData = async (userId) => {
    if (!userId) return;
    try {
      const userRef = doc(db, 'telegramUsers', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setBalance(userData.balance);
        setTapBalance(userData.tapBalance);
        setTapValue(userData.tapValue);
        setClaimedMilestones(userData.claimedMilestones || []);
        setClaimedReferralRewards(userData.claimedReferralRewards || []);
        setSelectedExchange(userData.selectedExchange);
        setSelectedCharacter(userData.character);
        setLastCheckIn(userData.lastCheckIn?.toDate() || null);
        setCheckInDays(userData.checkInDays || []);
        const data = userDoc.data().history || {};
        setWithdrawals(data.withdrawals || []);
        setDeposits(data.deposits || []);
        setSwaps(data.swaps || []);
        setFreeGuru(userData.freeGuru);
        setProfitHour(userData.profitHour || 0);
        setUserYoutubeTasks(userData.youtubeTasks || []);
        setWalletAddress(userData.address);
        setShowBalance(userData.showBalance);
        setIsAddressSaved(userData.isAddressSaved);
        setWalletAssets(userData.walletAssets || assets);
        setPurchasedCards(userData.specialCards || []);
        setEnergy(userData.energy);
        // Calculate total profits
        const total = purchasedCards.reduce((acc, card) => acc + card.profit, 0);
        setTotalCardsProfits(total);
        setFullName(userData.fullName);
        const span = userDoc.data().spinLimit ?? 10;
        setSpinLimit(span);
        setBattery(userData.battery);
        setLevel(userData.level);
        setId(userData.userId);
        setRefBonus(userData.refBonus || 0);
        setCompletedTasks(userData.tasksCompleted || []);
        setUserManualTasks(userData.manualTasks || []);
        setReferrals(userData.referrals || []);
        // FARm KM
        setMiningPower(userData.miningPower);
        setMiningTotal(userData.miningTotal);

        await updateActiveTime(userRef);
      }

      const tasksQuerySnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = tasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksData);

      const manualTasksQuerySnapshot = await getDocs(collection(db, 'manualTasks'));
      const manualTasksData = manualTasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setManualTasks(manualTasksData);

      // Fetch youtubeTasks
      const youtubeTasksQuerySnapshot = await getDocs(collection(db, 'youtubeTasks'));
      const youtubeTasksData = youtubeTasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setYoutubeTasks(youtubeTasksData);

      // KM leaderboard start
      const leadersQuerySnapshot = await getDocs(
        query(collection(db, 'leaderBoard'), orderBy('balance', 'desc'), limit(150))
      );
      const leadersData = leadersQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLeaderBoard(leadersData);

      // PROBLEM - Step 1: Query Firestore to find the first 500 users with a balance greater than the current user
      const usersAboveQuery = query(
        collection(db, 'telegramUsers'),
        where('balance', '>', balance),
        orderBy('balance', 'desc'),
        limit(150)
      );

      const querySnapshot = await getDocs(usersAboveQuery);

      // Step 2: If the user is found in the first 500, set the rank as the number of users with a greater balance + 1
      let activeUserRank;
      if (querySnapshot.size > 0) {
        activeUserRank = querySnapshot.size + 1; // Your user is in the top 500
      } else {
        activeUserRank = '150+'; // Your user is not in the top 500
      }

      setActiveUserRank(activeUserRank); // Set the active user rank
      // KM end

      // Fetch settings data
      const settingsDocRef = doc(db, 'settings', '1q01CYx0LFmgLR4wiUxX'); // Replace with your actual document ID
      const settingsDocSnap = await getDoc(settingsDocRef);

      if (settingsDocSnap.exists()) {
        const settingsData = settingsDocSnap.data();
        setCoolDownTime(settingsData.coolDownTime);
        setTappingGuru(settingsData.tappingGuru);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setLoading(false);
  };

  const sendUserData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    let referrerId = queryParams.get('ref');
    if (referrerId) {
      referrerId = referrerId.replace(/\D/g, '');
    }

    if (telegramUser) {
      const { id: userId, username, first_name: firstName, last_name: lastName } = telegramUser;
      const finalUsername = username || `${firstName}_${userId}`;
      const fullNamed = `${firstName} ${lastName}`;

      try {
        const userRef = doc(db, 'telegramUsers', userId.toString());
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          fetchData(userId.toString());
          await updateEnergy(userRef, userDoc.data().battery.energy);
          await updateReferrals(userRef);
          setInitialized(true);
          return;
        }

        const userData = {
          userId: userId.toString(),
          username: finalUsername,
          firstName: firstName,
          lastName: lastName,
          fullName: fullNamed,
          totalBalance: 0,
          showBalance: true,
          profitHour: 0,
          spinLimit: 10,
          isAddressSaved: false,
          address: '',
          balance: 0,
          tapBalance: 0,
          lastActive: new Date(),
          joinDate: new Date(),
          character: { name: '', avatar: '/user.webp' },
          freeGuru: 3,
          tapValue: { level: 1, value: 1 },
          level: { id: 1, name: 'Tamaq (Level 1)', imgUrl: '/level1.png' },
          selectedExchange: { id: 'selectex', icon: '/exchange.svg', name: 'Choose Land' },
          energy: 500,
          battery: { level: 1, energy: 500 },
          refereeId: referrerId || null,
          referrals: [],
          // FARM KM
          miningPower: 2500,
          miningTotal: 0,
        };

        await setDoc(userRef, userData);
        setEnergy(500);
        setFreeGuru(userData.freeGuru);
        setSelectedCharacter(userData.character);
        setFullName(fullNamed);
        // FARM KM
        setMiningPower(miningPower);
        setMiningTotal(userData.miningTotal);
        // FARKM KM END
        setCharacterMenu(true);
        setSelectedExchange({ id: 'selectex', name: 'Choose Land', icon: '/exchange.svg' });
        setId(userId.toString());

        if (referrerId) {
          const referrerRef = doc(db, 'telegramUsers', referrerId);
          const referrerDoc = await getDoc(referrerRef);
          if (referrerDoc.exists()) {
            await updateDoc(referrerRef, {
              referrals: arrayUnion({
                userId: userId.toString(),
                username: finalUsername,
                balance: 0,
                level: { id: 1, name: 'Tamaq (Level 1)', imgUrl: '/level1.png' },
              }),
            });
          }
        }
        setInitialized(true);
        fetchData(userId.toString());
      } catch (error) {
        console.error('Error saving user in Firestore:', error);
      }
    }
  };

  const updateEnergy = async (userRef, batteryValue) => {
    const savedEndTime = localStorage.getItem('endTime');
    const savedEnergy = localStorage.getItem('energy');
    const endTime = new Date(savedEndTime);
    const newTimeLeft = endTime - new Date();
    if (newTimeLeft < 0 && savedEnergy <= 0) {
      try {
        await updateDoc(userRef, { energy: batteryValue });
        setEnergy(batteryValue);
      } catch (error) {
        console.error('Error updating energy:', error);
      }
    }
  };

  const updateActiveTime = async (userRef) => {
    try {
      await updateDoc(userRef, {
        lastActive: new Date(),
      });
      console.log('Active Time Updated');
    } catch (error) {
      console.error('Error updating Active Time:', error);
    }
  };

  const updateSpins = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastDate = userData.slotTimeStamp.toDate(); // Convert Firestore timestamp to JS Date
      const formattedDates = lastDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
      const currentDate = new Date(); // Get the current date
      const formattedCurrentDates = currentDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format

      if (formattedDates !== formattedCurrentDates && userData.spinLimit <= 0) {
        await updateDoc(userRef, {
          spinLimit: 10,
          slotTimeStamp: new Date(),
        });
        setSpinLimit(10);
      }
    }
  };

  const updateReferrals = async (userRef) => {
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    const referrals = userData.referrals || [];

    const updatedReferrals = await Promise.all(
      referrals.map(async (referral) => {
        const referralRef = doc(db, 'telegramUsers', referral.userId);
        const referralDoc = await getDoc(referralRef);
        if (referralDoc.exists()) {
          const referralData = referralDoc.data();
          return {
            ...referral,
            balance: referralData.balance,
            level: referralData.level,
          };
        }
        return referral;
      })
    );

    await updateDoc(userRef, { referrals: updatedReferrals });

    const totalEarnings = updatedReferrals.reduce((acc, curr) => acc + curr.balance, 0);
    const refBonus = Math.floor(totalEarnings * 0.1);
    const totalBalance = `${balance}` + refBonus;
    try {
      await updateDoc(userRef, { refBonus, totalBalance, lastActive: new Date() });
    } catch (error) {
      console.error('Error updating referrer bonus:', error);
    }
  };

  const updateUserLevel = async (userId, newTapBalance) => {
    let newLevel = { id: 1, name: 'Tamaq (Level 1)', imgUrl: '/level1.png' };

    if (newTapBalance >= 5000 && newTapBalance < 100000) {
      newLevel = { id: 2, name: 'Oranu (Level 2)', imgUrl: '/level2.png' };
    } else if (newTapBalance >= 100000 && newTapBalance < 1000000) {
      newLevel = { id: 3, name: 'Xaruk (Level 3)', imgUrl: '/level3.png' };
    } else if (newTapBalance >= 1000000 && newTapBalance < 5000000) {
      newLevel = { id: 4, name: 'Maloko (Level 4)', imgUrl: '/level4.png' };
    } else if (newTapBalance >= 5000000 && newTapBalance < 25000000) {
      newLevel = { id: 5, name: 'Venaru (Level 5)', imgUrl: '/level5.png' };
    } else if (newTapBalance >= 25000000 && newTapBalance >= 100000000) {
      newLevel = { id: 6, name: 'Rokama (Level 6)', imgUrl: '/level6.png' };
    } else if (newTapBalance >= 100000000 && newTapBalance >= 300000000) {
      newLevel = { id: 6, name: 'Xatar (Level 7)', imgUrl: '/level7.png' };
    } else if (newTapBalance >= 300000000 && newTapBalance >= 1000000000) {
      newLevel = { id: 6, name: 'Kulvano (Level 8)', imgUrl: '/level8.png' };
    } else if (newTapBalance >= 1000000000 && newTapBalance >= 5000000000) {
      newLevel = { id: 6, name: 'Lorak (Level 9)', imgUrl: '/level9.png' };
    } else if (newTapBalance >= 10000000000) {
      newLevel = { id: 6, name: 'Thamoru (Level 10)', imgUrl: '/level10.png' };
    }

    if (newLevel.id !== level.id) {
      setLevel(newLevel);
      const userRef = doc(db, 'telegramUsers', userId);
      await updateDoc(userRef, { level: newLevel });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'telegramUsers', id.toString());
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Handle prTeam
        if (userData.prTeam) {
          const updatedLevels = prTeam.map((team) => {
            const teamData = userData.prTeam.find((t) => t.title === team.title);
            return teamData ? teamData.level : 0;
          });

          const updatedProfits = prTeam.map((team) => {
            const teamData = userData.prTeam.find((t) => t.title === team.title);
            return teamData ? teamData.totalProfit : 0;
          });

          setUserLevels(updatedLevels);
          setTotalProfit(updatedProfits);
        }

        // Handle marketTeam
        if (userData.marketTeam) {
          const updatedLevelsMarket = marketTeam.map((market) => {
            const marketData = userData.marketTeam.find((t) => t.title === market.title);
            return marketData ? marketData.level : 0;
          });

          const updatedMarketProfits = marketTeam.map((market) => {
            const marketData = userData.marketTeam.find((t) => t.title === market.title);
            return marketData ? marketData.totalMarketProfit : 0;
          });

          setUserLevelsMarket(updatedLevelsMarket);
          setTotalMarketProfit(updatedMarketProfits);
        }
      } else {
        console.error('User document does not exist');
      }
    };

    fetchUserData();
  }, [id]);

  const checkAndUpdateFreeGuru = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastDate = userData.timeSta.toDate(); // Convert Firestore timestamp to JS Date
      const formattedDates = lastDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
      const currentDate = new Date(); // Get the current date
      const formattedCurrentDates = currentDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format

      if (formattedDates !== formattedCurrentDates && userData.freeGuru <= 0) {
        await updateDoc(userRef, {
          freeGuru: 3,
          timeSta: new Date(),
        });
        setFreeGuru(3);
      }
    }
  };

  useEffect(() => {
    const rewards = document.getElementById('reelsActivities');
    const rewardsTwo = document.getElementById('reels2Activities');

    if (location.pathname === '/rewards' || location.pathname === '/checkin') {
      rewards.style.background = '#935EDB';
      rewards.style.color = '#fff';
      rewardsTwo.style.color = '#fff';
      rewards.style.height = '60px';
      rewards.style.marginTop = '4px';
      rewards.style.paddingLeft = '6px';
      rewards.style.paddingRight = '6px';
    } else {
      rewards.style.background = '';
      rewards.style.color = '';
      rewards.style.height = '';
      rewards.style.marginTop = '';
      rewardsTwo.style.color = '';
      rewards.style.paddingLeft = '';
      rewards.style.paddingRight = '';
    }
  }, [location.pathname]);

  useEffect(() => {
    // Fetch the remaining clicks from Firestore when the component mounts
    const fetchRemainingClicks = async () => {
      if (id) {
        const userRef = doc(db, 'telegramUsers', id.toString());
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFreeGuru(userData.freeGuru || 0);
        }
      }
    };

    fetchRemainingClicks();
  }, [id]);

  useEffect(() => {
    // Calculate the new balance by adding balance and refBonus
    const newBalance = balance + refBonus;

    // Find the current 'XP' token in walletAssets
    const maxToken = walletAssets.find((asset) => asset.symbol === 'XP');

    // Check if maxToken exists and if its balance is different from the newBalance
    if (maxToken && maxToken.balance !== newBalance) {
      // Update the balance for the 'LYYC' token
      setWalletAssets((prevAssets) =>
        prevAssets.map((asset) => (asset.symbol === 'XP' ? { ...asset, balance: newBalance } : asset))
      );
    }
  }, [balance, refBonus, walletAssets]);

  useEffect(() => {
    const checkLastCheckIn = async () => {
      if (!id) return;

      try {
        const userDocRef = doc(db, 'telegramUsers', id);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const now = new Date();

          const lastCheckInDate = userData.lastCheckIn?.toDate();

          if (lastCheckInDate) {
            const lastCheckInMidnight = new Date(lastCheckInDate);
            lastCheckInMidnight.setHours(0, 0, 0, 0);

            const todayMidnight = new Date(now);
            todayMidnight.setHours(0, 0, 0, 0);

            const daysSinceLastCheckIn = Math.floor((todayMidnight - lastCheckInMidnight) / (1000 * 60 * 60 * 24));

            if (daysSinceLastCheckIn === 1) {
              // Last check-in was yesterday, prompt user to claim today's bonus
              setShowClaimModal(true);
            } else if (daysSinceLastCheckIn > 1) {
              // User missed a day, show the start over modal
              setShowStartOverModal(true);
            }
          } else {
            // First time check-in, set the check-in modal to be shown
            setShowClaimModal(true);
          }
        }
      } catch (err) {
        console.error('Error during initial check-in:', err);
        setError('An error occurred while checking your last check-in.');
      }
    };

    checkLastCheckIn();
  }, [id, setCheckInDays, setError]);

  useEffect(() => {
    if (id) {
      checkAndUpdateFreeGuru();
    }
    if (selectedCharacter.name === '') {
      setCharacterMenu(true);
    } else {
      setCharacterMenu(false);
    }
    updateSpins();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    sendUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id) {
      updateUserLevel(id, tapBalance);
    }
    // eslint-disable-next-line
  }, [tapBalance, id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <UserContext.Provider
      value={{
        balance,
        specialCards,
        fullName,
        youtubeTasks,
        setYoutubeTasks,
        userYoutubeTasks,
        setUserYoutubeTasks,
        purchasedCards,
        withdrawals,
        setWithdrawals,
        deposits,
        setDeposits,
        swaps,
        setSwaps,
        walletAssets,
        setWalletAssets,
        setPurchasedCards,
        totalCardsProfits,
        setTotalCardsProfits,
        userLevelss,
        success,
        setSuccess,
        userLevels,
        setUserLevels,
        totalMarketProfit,
        setTotalMarketProfit,
        userLevelsMarket,
        setUserLevelsMarket,
        prTeam,
        marketTeam,
        totalProfit,
        setTotalProfit,
        profitHour,
        setProfitHour,
        showStartOverModal,
        setShowStartOverModal,
        showClaimModal,
        setShowClaimModal,
        spinLimit,
        setSpinLimit,
        lastCheckIn,
        setLastCheckIn,
        checkInDays,
        setCheckInDays,
        error,
        setError,
        showBalance,
        setShowBalance,
        openInfoTwo,
        setOpenInfoTwo,
        openInfoThree,
        setOpenInfoThree,
        setFullName,
        coolDownTime,
        setCoolDownTime,
        tappingGuru,
        setTappingGuru,
        lastTime,
        walletAddress,
        setWalletAddress,
        isAddressSaved,
        setIsAddressSaved,
        selectedCharacter,
        setSelectedCharacter,
        characterMenu,
        setCharacterMenu,
        setLastTime,
        claimExchangePoint,
        setClaimExchangePoint,
        battery,
        freeGuru,
        setFreeGuru,
        isTimerRunning,
        setIsTimerRunning,
        time,
        setTime,
        startTimer,
        setBattery,
        tapGuru,
        setTapGuru,
        mainTap,
        setMainTap,
        selectedExchange,
        setSelectedExchange,
        tapValue,
        setTapValue,
        tapBalance,
        setTapBalance,
        level,
        energy,
        setEnergy,
        setBalance,
        setLevel,
        loading,
        setLoading,
        id,
        setId,
        sendUserData,
        leaderBoard,
        setLeaderBoard,
        activeUserRank,
        setActiveUserRank,
        initialized,
        setInitialized,
        refBonus,
        setRefBonus,
        manualTasks,
        setManualTasks,
        userManualTasks,
        setUserManualTasks,
        tasks,
        setTasks,
        completedTasks,
        setCompletedTasks,
        claimedMilestones,
        setClaimedMilestones,
        referrals,
        claimedReferralRewards,
        setClaimedReferralRewards,
        isGameOpened,
        setIsGameOpened,
        // FARM KM
        miningPower,
        setMiningPower,
        miningTotal,
        setMiningTotal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
