import React, { useEffect, useRef, useState } from 'react';
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { doc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';

export const Address = () => {
  const { id, isAddressSaved, setWalletAddress, setIsAddressSaved } = useUser();
  const [walletAddressInput, setWalletAddressInput] = useState('');
  const [disconnect, setDisconnect] = useState(false);

  const [openInfoTwo, setOpenInfoTwo] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const infoRefTwo = useRef(null);
  const infoRef = useRef(null);

  // Load wallet address and save status from local storage on page load
  useEffect(() => {
    const savedAddress = localStorage.getItem('walletAddress');
    const savedIsAddress = localStorage.getItem('isAddressSaved') === 'true';

    if (savedAddress) {
      setWalletAddressInput(savedAddress);
      setIsAddressSaved(savedIsAddress);
    }
  }, [setIsAddressSaved]);

  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setOpenInfo(false);
    }
  };

  useEffect(() => {
    if (openInfoTwo || openInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openInfoTwo, openInfo]);

  const clearCache = () => {
    localStorage.clear();
    sessionStorage.clear();
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
  };

  // Disconnect function
  const handleDisconnect = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: '',
        isAddressSaved: false,
      });
      setIsAddressSaved(false);
      setWalletAddress('');
      localStorage.removeItem('walletAddress');
      localStorage.removeItem('isAddressSaved');
      setDisconnect(false);
      setOpenInfo(false); // Close the popup after disconnect
      console.log('Address disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting address:', error);
    }
  };

  // Function to toggle disconnect state
  const disconn = async () => {
    setDisconnect(!disconnect);
  };

  // Function to save address to Firestore and automatically close popup
  const saveAddressToFirestore = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: walletAddressInput,
        isAddressSaved: true,
      });
      setIsAddressSaved(true);
      setWalletAddress(walletAddressInput);
      localStorage.setItem('walletAddress', walletAddressInput); // Save to local storage
      localStorage.setItem('isAddressSaved', 'true'); // Save the save status
      console.log('Address saved successfully');
      setOpenInfoTwo(false); // Automatically close the popup
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };

  return (
    <>
      <div className="w-full rounded-[15px] flex flex-col justify-center items-end relative">
        {isAddressSaved ? (
          <button
            onClick={() => setOpenInfo(true)}
            className={`bg-[#a4a4a424] flex h-full w-fit rounded-full items-center py-[10px] px-3 relative space-x-1`}
          >
            <img src='/wallet.webp' alt="connect" className="w-[16px] -mt-[2px]" />
            <div className="text-[13px] small-text2 text-left pr-3 text-nowrap text-white flex flex-1 flex-col">
              <h4 className='font-semibold text-[#d1d1d1] line-clamp-1 break-all text-wrap'>{walletAddressInput}</h4>
            </div>
            <IoCheckmarkCircle size={20} className='text-[#40863d]' />
          </button>
        ) : (
          <button
            onClick={() => setOpenInfoTwo(true)}
            className={`bsg-[#319cdf74] bg-[#319cdf] flex h-full w-fit rounded-full items-center py-[10px] px-3 relative space-x-1`}
          >
            <img src='/wallet.webp' alt="connect" className="w-[16px] -mt-[2px]" />
            <div className="text-[13px] small-text2 text-left pr-6 text-nowrap text-white flex flex-1 flex-col">
              Enter wallet address
            </div>
            <MdOutlineKeyboardArrowRight size={20} className='text-[#fff] absolute right-2' />
          </button>
        )}
      </div>

      {/* Popup for manually entering wallet address */}
      <div
        className={`${
          openInfoTwo === true ? "visible" : "invisible"
        } fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
        <div
          ref={infoRefTwo}
          className={`${
            openInfoTwo === true
              ? "opacity-100 mt-0 ease-in duration-300"
              : "opacity-0 mt-[100px]"
          } w-full bg-modal absolute bottom-0 left-0 right-0 rounded-tr-[20px] rounded-tl-[20px] flex flex-col justify-center p-8`}
        >
          <div className="w-fit flex justify-center absolute right-6 top-6">
            <button
              onClick={() => setOpenInfoTwo(false)}
              className="w-fit flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
            >
              <IoCloseCircle size={32} className="text-[#8f8f8f]" />
            </button>
          </div>

          <div className="w-full flex justify-center flex-col items-center space-y-3 pt-6 pb-32">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <h3 className="font-semibold text-[22px] w-full text-center">
                Enter your Wallet Address
              </h3>
              <p className="pb-6 text-[#bfbfbf] text-[14px] w-full text-center">
                Enter your SOL wallet address to save it for future transactions.
              </p>
              <input
                type="text"
                className="w-full py-2 px-3 border border-gray-300 rounded-lg text-black"
                placeholder="Enter wallet address"
                value={walletAddressInput}
                onChange={(e) => setWalletAddressInput(e.target.value)}
              />
              <button
                onClick={saveAddressToFirestore}
                className="bg-green-500 w-full py-2 px-8 text-center rounded-[25px] mt-4"
              >
                Save Address
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Disconnect popup */}
      <div
        className={`${
          openInfo === true ? "visible" : "invisible"
        } fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
        <div
          ref={infoRef}
          className={`${
            openInfo === true
              ? "opacity-100 mt-0 ease-in duration-300"
              : "opacity-0 mt-[100px]"
          } w-full bg-modal absolute bottom-0 left-0 right-0 rounded-tr-[20px] rounded-tl-[20px] flex flex-col justify-center p-8`}
        >
          <div className="w-fit flex justify-center absolute right-6 top-6">
            <button
              onClick={() => setOpenInfo(false)}
              className="w-fit flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
            >
              <IoCloseCircle size={32} className="text-[#8f8f8f]" />
            </button>
          </div>

          <div className="w-full flex justify-center flex-col items-center space-y-3 pt-6 pb-32">
            <h3 className="font-semibold text-green-500 text-[22px] w-full text-center">Your wallet is connected</h3>
            <p className="pb-6 text-[#bfbfbf] text-[14px] w-full text-center">
              Continue performing tasks and await airdrop distribution!
            </p>

            <button
              onClick={disconn}
              className='bg-red-500 w-fit py-2 px-10 text-center rounded-[25px]'
            >
              Disconnect wallet
            </button>

            <div className={`${disconnect ? 'flex' : 'hidden'} px-4 py-2 text-[13px] text-center rounded-[8px] flex flex-col`}>
              <p className='text-[#ffffff] pb-2'>
                Are you sure you want to disconnect? Only connected wallets are eligible for airdrop.
              </p>
              <div className='w-full flex justify-center'>
                <button
                  onClick={handleDisconnect}
                  className='font-medium bg-[#eb4848] w-fit rounded-[25px] px-4 py-1'
                >
                  Yes, Disconnect wallet
                </button>
              </div>
            </div>

            <button
              onClick={() => setOpenInfo(false)}
              className='bg-[#fff] text-[#000] py-2 px-8 text-center rounded-[25px]'
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
