import React,{useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ErrorCom from './Components/ErrorCom';
import Boost from './pages/Boost';
import Wallet from './pages/Wallet';
import Leaderboard from './pages/Leaderboard';
import TasksList from './pages/Tasks';
import ReferralRewards from './pages/Rewards';
import Dashboard from './pages/Dashboard';
import NotAdmin236 from './pages/NotAdmin236';
import Settings from './pages/Settings';
import TGMessage from './pages/TGMessage';
import EditTasks from './pages/EditTasks';
import ExtrenalTasks from './pages/ExtrenalTasks';
import Search from './pages/Search';
import Statistics from './pages/Statistics';
import { AuthContextProvider } from './context/AuthContext';
import GoldHunters from './pages/GoldHunters';
import Roulette from './pages/Roulette';
import Mine from './pages/Mine';
import Qualify from './pages/Qualify';
import History from './pages/History';
import AdminYoutube from './pages/AdminYoutube';
import GameEvents from './pages/AdminGameEvents';
import AirdropWallets from './pages/AirdropWallets';
import DailyCheckIn from './pages/Checkin';
import AdminRanks from './pages/AdminRanks';
import MiniGames from './pages/MiniGames';
import Events from './pages/Events';
import GoldMinerGame from './pages/GoldMiner';
import CryptoFarming from './pages/Farm';
import FatBoy from './pages/FatBoy';
import Sling from './pages/Sling';
import Aliens from './pages/Aliens';
import TropicBlasters from './pages/TropicBlasters';
import HexaGame from './pages/HexaGame';
import BananaFrenzy from './pages/BananaFrenzy';
import ProtectedRoute from './Components/ProtectedRoute'; // Import the ProtectedRoute
import NotAllowed from './pages/NotAllowed'; 
import Airdrop from './pages/Airdrop';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-3QXXQZ0YWX');

{/**
  element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    )
  */}

const router = createBrowserRouter([
  {
    path: '/',
      element: <Home />,  
  /*
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  */
    errorElement: <ErrorCom />,
    children: [
      {
        path: '/',
        element: <GoldHunters />,
      },
      {
        path: '/roulette',
        element: <Roulette />,
      },
      {
        path: '/mine',
        element: <Mine />,
      },
      {
        path: '/checkin',
        element: <DailyCheckIn />,
      },
      {
        path: '/qualify',
        element: <Qualify />,
      },
      {
        path: '/history',
        element: <History />,
      },
      {
        path: '/tasks',
        element: <TasksList />,
      },
      {
        path: '/boost',
        element: <Boost />,
      },
      {
        path: '/wallet',
        element: <Wallet />,
      },
      {
        path: '/miniGames',
        element: <MiniGames />,
      },
      {
        path: '/events',
        element: <Events />,
      },
      {
        path: '/airdrop',
        element: <Airdrop />,
      },
      {
        path: '/moonbix',
        element: <GoldMinerGame />,
      },
      {
        path: '/farm',
        element: <CryptoFarming />,
      },
      {
        path: '/leaderboard',
        element: <Leaderboard />,
      },
      {
        path: '/rewards',
        element: <ReferralRewards />,
      },
      {
        path: '/dashboardadmin36024x',
        element: <NotAdmin236 />,
      },
      {
        path: '/aliens',
        element: <Aliens />,
      },
      {
        path: '/FatBoy',
        element: <FatBoy />,
      },
      {
        path: '/Sling',
        element: <Sling />,
      },
      {
        path: '/bananafrenzy',
        element: <BananaFrenzy />,
      },
      {
        path: '/tropic-blaster',
        element: <TropicBlasters />,
      },
      {
        path: '/hexa-game',
        element: <HexaGame />,
      },
     
    ],
  },
  {
    path: '/dashboardAdx',
    element: <Dashboard />,
    errorElement: <ErrorCom />,
    children: [
      {
        path: '/dashboardAdx/settings',
        element: <Settings />,
      },
      {
        path: '/dashboardAdx/TGMessage',
        element: <TGMessage />,
      },
      {
        path: '/dashboardAdx/managetasks',
        element: <EditTasks />,
      },
      {
        path: '/dashboardAdx/externaltasks',
        element: <ExtrenalTasks />,
      },
      {
        path: '/dashboardAdx/youtube',
        element: <AdminYoutube />,
      },
      {
        path: '/dashboardAdx/ranks',
        element: <AdminRanks />,
      },
      {
        path: '/dashboardAdx/gameevents',
        element: <GameEvents />,
      },
      {
        path: '/dashboardAdx/wallets',
        element: <AirdropWallets />,
      },
      {
        path: '/dashboardAdx/search',
        element: <Search />,
      },
      {
        path: '/dashboardAdx/stats',
        element: <Statistics />,
      },
    ],
  },
  {
    path: '/not-allowed',
    element: <NotAllowed />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <AuthContextProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </AuthContextProvider>
  );
};

root.render(<App />);