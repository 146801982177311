import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore'; // adjust the path as needed
import { collection, where, getDocs, query, limit, startAfter, orderBy } from "firebase/firestore";
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { PiArrowRight } from 'react-icons/pi';
import Spinner from './Spinner';

const linksTo = [
  { link: '/dashboardAdx/managetasks', title: 'Telegram Tasks' },
  { link: '/dashboardAdx/externaltasks', title: 'Promo/External Tasks' },
  { link: '/dashboardAdx/youtube', title: 'Youtube Tasks' },
  { link: '/dashboardAdx/wallets', title: 'Airdrop list' },
  { link: '/dashboardAdx/search', title: 'Search user' },
  { link: '/dashboardAdx/settings', title: 'Settings' },
];

const BATCH_SIZE = 500;

const StatisticsPanel = () => {
  const [usersData, setUsersData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalTapBalance, setTotalTapBalance] = useState(0);

  const [activeUserstotal, setActiveUserstotal] = useState(0);
  
  const [activeUsersLast7d, setActiveUsersLast7d] = useState(0);
  const [activeUsersLast30d, setActiveUsersLast30d] = useState(0);
  const [activeUsersLast24Hours, setActiveUsersLast24Hours] = useState(0);

  const [activeTUsersLast7d, setActiveTUsersLast7d] = useState(0);
  const [activeTUsersLast30d, setActiveTUsersLast30d] = useState(0);
  const [activeTUsersLast24Hours, setActiveTUsersLast24Hours] = useState(0);
  
  const [activeUsersLast1Hour, setActiveUsersLast1Hour] = useState(0);
  const [activeUsersLast1Minute, setActiveUsersLast1Minute] = useState(0);

  const [totalUsersLast7d, setTotalUsersLast7d] = useState(0);
  const [totalUsersLast30d, setTotalUsersLast30d] = useState(0);
  const [totalUsersLast24Hours, setTotalUsersLast24Hours] = useState(0);

  const [totalL1, setTotalL1] = useState(0);
  const [totalL1_u, setTotalL1_u] = useState(0);
  const [totalL2, setTotalL2] = useState(0);
  const [totalL2_u, setTotalL2_u] = useState(0);
  const [totalL3, setTotalL3] = useState(0);
  const [totalL3_u, setTotalL3_u] = useState(0);

  const [loading, setLoading] = useState(false);
  const [autoLoad, setAutoLoad] = useState(false); // if true, automatically load next batch after one is finished

  useEffect(() => {
    // Initial load of first 50 users
    fetchUsersBatch();
    fetchLoginData1();
    fetchLoginData2();
    fetchLoginData3();
  }, []);

  useEffect(() => {
    // If autoLoad is true and we still have more data, load next batch automatically
    if (autoLoad && hasMore && !loading) {
      fetchUsersBatch();
    }
  }, [autoLoad, hasMore, loading]);

  const fetchUsersBatch = async () => {
    setLoading(true);

    let baseQuery = query(
      collection(db, "telegramUsers"),
      orderBy("lastActive", "desc"), // ensure consistent ordering by a field
      limit(BATCH_SIZE)
    );

    if (lastDoc) {
      baseQuery = query(
        collection(db, "telegramUsers"),
        orderBy("lastActive", "desc"),
        startAfter(lastDoc),
        limit(BATCH_SIZE)
      );
    }

    const snapshot = await getDocs(baseQuery);
    if (snapshot.empty) {
      setHasMore(false);
      setLoading(false);
      return;
    }

    const newUsers = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    const combinedUsers = [...usersData, ...newUsers];

    setUsersData(combinedUsers);
    setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

    // Update stats with the newly combined data
    updateStatistics(combinedUsers);

    if (newUsers.length < BATCH_SIZE) {
      // Less than BATCH_SIZE means no more data is available
      setHasMore(false);
    }

    setLoading(false);
  };

  const fetchLoginData1 = async () => {
    try {
      var total = [];
      const loginsCollectionRef = collection(db, "logins");
      const limit = moment().subtract(24, "hours").toDate();
      const q = query(loginsCollectionRef, where("loginDate", ">", limit));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        total.push(data.userId);
      });
      var unique = total.filter((item, index) => total.indexOf(item) === index);
      const totalL1_u = unique.length;
      const totalL1 = total.length;
      setTotalL1(totalL1);
      setTotalL1_u(totalL1_u);
    } catch (error) {
      console.error("Error fetching login data:", error);
      return [];
    }
  };

  const fetchLoginData2 = async () => {
    try {
      var total = [];
      const loginsCollectionRef = collection(db, "logins");
      const limit = moment().subtract(168, "hours").toDate();
      const q = query(loginsCollectionRef, where("loginDate", ">", limit));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        total.push(data.userId);
      });
      var unique = total.filter((item, index) => total.indexOf(item) === index);
      const totalL2_u = unique.length;
      const totalL2 = total.length;
      setTotalL2(totalL2);
      setTotalL2_u(totalL2_u);
    } catch (error) {
      console.error("Error fetching login data:", error);
      return [];
    }
  };

  const fetchLoginData3 = async () => {
    try {
      var total = [];
      const loginsCollectionRef = collection(db, "logins");
      const limit = moment().subtract(720, "hours").toDate();
      const q = query(loginsCollectionRef, where("loginDate", ">", limit));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        total.push(data.userId);
      });
      var unique = total.filter((item, index) => total.indexOf(item) === index);
      const totalL3_u = unique.length;
      const totalL3 = total.length;
      setTotalL3(totalL3);
      setTotalL3_u(totalL3_u);
    } catch (error) {
      console.error("Error fetching login data:", error);
      return [];
    }
  };

    const updateStatistics = (allUsersData) => {

    const last7d = moment().subtract(168, 'hours').toDate();
    const last30d = moment().subtract(720, 'hours').toDate();
    const last24Hours = moment().subtract(24, 'hours').toDate();
    const last1Hour = moment().subtract(1, 'hour').toDate();
    const last1Minute = moment().subtract(1, 'minute').toDate();

    // Partial totals based on loaded data
    const totalUsersCount = allUsersData.length;
    const totalBalanceSum = allUsersData.reduce((acc, user) => acc + (user.balance || 0), 0);
    const totalTapBalanceSum = allUsersData.reduce((acc, user) => acc + (user.tapBalance || 0), 0);

    const activeUsers24Hours = allUsersData.filter(user => user.joinDate && user.lastActive && user.lastActive.toDate() > last24Hours && user.joinDate.toDate() > last24Hours).length;
    const activeUsers7d = allUsersData.filter(user => user.joinDate && user.lastActive && user.lastActive.toDate() > last7d && user.joinDate.toDate() > last7d).length;
    const activeUsers30d = allUsersData.filter(user => user.joinDate && user.lastActive && user.lastActive.toDate() > last30d && user.joinDate.toDate() > last30d).length;

    const activeTUsers24Hours = allUsersData.filter(user => user.lastActive && user.lastActive.toDate() > last24Hours).length;
    const activeTUsers7d = allUsersData.filter(user => user.lastActive && user.lastActive.toDate() > last7d).length;
    const activeTUsers30d = allUsersData.filter(user => user.lastActive && user.lastActive.toDate() > last30d).length;

    const totalUsers7d = allUsersData.filter(user => user.joinDate && user.joinDate.toDate() > last7d).length;
    const totalUsers30d = allUsersData.filter(user => user.joinDate && user.joinDate.toDate() > last30d).length;
    const totalUsers24Hours = allUsersData.filter(user => user.joinDate && user.joinDate.toDate() > last24Hours).length;

    const activeUsers1Hour = allUsersData.filter(user => user.lastActive && user.lastActive.toDate() > last1Hour).length;
    const activeUsers1Minute = allUsersData.filter(user => user.lastActive && user.lastActive.toDate() > last1Minute).length;

    

    setTotalUsers(totalUsersCount);
    setTotalBalance(totalBalanceSum);
    setTotalTapBalance(totalTapBalanceSum);    
    setActiveUserstotal(activeUserstotal);

    setActiveUsersLast7d(activeUsers7d);
    setActiveUsersLast30d(activeUsers30d);
    setActiveUsersLast24Hours(activeUsers24Hours);

    setActiveTUsersLast7d(activeTUsers7d);
    setActiveTUsersLast30d(activeTUsers30d);
    setActiveTUsersLast24Hours(activeTUsers24Hours);

    setActiveUsersLast1Hour(activeUsers1Hour);
    setActiveUsersLast1Minute(activeUsers1Minute);

    setTotalUsersLast7d(totalUsers7d);
    setTotalUsersLast30d(totalUsers30d);
    setTotalUsersLast24Hours(totalUsers24Hours);


  };

  const formatNumber = (num) => {
    if (typeof num !== "number") {
      return "Invalid number";
    }

    if (num < 1 && num.toString().split('.')[1]?.length > 3) {
      return num.toFixed(6).replace(/0+$/, '');
    }

    return num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const statista = [
    { title: 'Total Users (Loaded)', count: totalUsers },
    { title: 'Total Balance (Loaded)', count: formatNumber(totalBalance) },
    { title: 'Total Taps (Loaded)', count: formatNumber(totalTapBalance) },
    { title: 'Last 24hours (Loaded)', count: activeUsersLast24Hours },
    { title: 'Last 1hour (Loaded)', count: activeUsersLast1Hour },
    { title: 'Online Users (Last 1min)', count: activeUsersLast1Minute },

    { title: 'New Users (24 hours)', count: totalUsersLast24Hours },
    { title: 'New Users (1 Week)', count: totalUsersLast7d },
    { title: 'New Users (1 Month)', count: totalUsersLast30d },

    { title: 'Active Total Users (24 hours)', count: activeTUsersLast24Hours },
    { title: 'Active Total Users (1 Week)', count: activeTUsersLast7d },
    { title: 'Active Total Users (1 Month)', count: activeTUsersLast30d },

    { title: 'Active New Users (24 hours)', count: activeUsersLast24Hours },
    { title: 'Active New Users (1 Week)', count: activeUsersLast7d },
    { title: 'Active New Users (1 Month)', count: activeUsersLast30d },

    { title: 'Logins (24 Hours)', count: totalL1+" | "+totalL1_u},
    { title: 'Logins (1 Week)', count: totalL2+" | "+totalL2_u},
    { title: 'Logins (1 Month)', count: totalL3+" | "+totalL3_u},


  ];

  const handleLoadAllClick = () => {
    // Start the auto-load process if not already started
    if (!autoLoad && hasMore) {
      setAutoLoad(true);
    }
  };

  return (
    <>
      {usersData.length === 0 && loading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px]">
          <div className="w-full flex justify-start items-start flex-wrap gap-4">
            {statista.map((stats, index) => (
              <div
                key={index}
                className="bg-cards p-4 rounded-[10px] w-[47%] sm:w-[32%] h-[120px] flex flex-col justify-center space-y-3"
              >
                <h2 className="text-[16px] sm:text-[18px] font-semibold text-[#bdbdbd]">
                  {stats.title}
                </h2>
                <span className='text-[20px] sm:text-[24px] text-[#fff] font-bold'>
                  {stats.count}
                </span>
              </div>
            ))}
          </div>

          {!autoLoad && hasMore && (
            <div className='w-full flex justify-center mt-4'>
              <button
                onClick={handleLoadAllClick}
                className="bg-blue-600 text-white px-4 py-2 rounded"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Load All Automatically'}
              </button>
            </div>
          )}

          {autoLoad && loading && (
            <div className="w-full flex justify-center mt-4">
              <div className="text-white font-semibold">Loading more users...</div>
            </div>
          )}

          {!hasMore && autoLoad && (
            <div className="w-full flex justify-center mt-4">
              <div className="text-white font-semibold">All users loaded!</div>
            </div>
          )}

          <h2 className='font-semibold text-[17px] pt-3'>Admin Control Items</h2>
          <div className='flex flex-col space-y-4 w-full'>
            {linksTo.map((menu, index) => (
              <NavLink to={menu.link} key={index} className="bg-cards px-4 py-4 flex rounded-[6px] justify-between items-center space-x-1 font-medium">
                <span>{menu.title}</span>
                <span><PiArrowRight size={16}/></span>
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StatisticsPanel;
