import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useUser } from '../context/userContext';

const Footer = () => {
  const location = useLocation();
  const { selectedExchange } = useUser();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const footerLinks = [
    {
      title: 'Fight',
      link: '/',
      icon: (
        <img
          id={selectedExchange.id}
          src="/fight.png"
          alt="fight"
          className="w-[40px]"
        />
      ),
    },
    {
      title: 'Games',
      link: '/miniGames',
      icon: (
        <img
          src="/games.png"
          alt="games"
          className="w-[40px]"
        />
      ),
    },
    {
      title: 'EARN',
      link: '/mine',
      icon: (
        <img
          src="/earn.png"
          alt="earn"
          className="w-[40px]"
        />
      ),
    },
    {
      title: 'Activities',
      link: '/tasks',
      icon: (
        <img
          src="/tasks.png"
          alt="activities"
          className="w-[40px]"
        />
      ),
    },
    {
      title: 'Wallet',
      link: '/wallet',
      icon: (
        <img
          src="/wallet.png"
          alt="wallet"
          className="w-[40px]"
        />
      ),
    },
  ];

  // Collapse/Expand button configuration
  const collapseButton = {
    title: collapsed ? 'Expand' : 'Collapse',
    link: '#',
    icon: (
      <img
        src={collapsed ? "/collapse.png" : "/expand.png"}
        alt="toggle"
        className={` p-[5px] ${
                collapsed ? 'w-[20px]' : 'w-[40px]'
              }`}
      />
    ),
    action: toggleCollapse,
  };

  // Determine which links to render based on collapsed state
  const linksToRender = collapsed ? [collapseButton] : [...footerLinks, collapseButton];

  return (
    <div
      className={`z-30 fixed bottom-4 left-0 right-0 mx-auto max-w-md flex items-center px-4 transition-all duration-300 ${
        collapsed
          ? 'h-12 bg-transparent border-0 justify-center'
          : 'h-16 justify-between bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 bg-opacity-80 backdrop-blur-lg rounded-full shadow-xl border border-gray-700'
      }`}
      style={{ background: "transparent", border: "0px" }}
    >
      {linksToRender.map((footer, index) => {
        // If there's an action defined (like our collapse/expand button),
        // use a button instead of NavLink
        if (footer.action) {
          return (
            <button
              key={index}
              onClick={footer.action}
              className={`flex flex-col  ${
                collapsed ? 'items-right justify-right' : 'items-center justify-center w-1/4 '
              } h-full transition-transform duration-200 transform text-gray-400 hover:scale-105`}
              aria-expanded={!collapsed}
              aria-label={collapsed ? 'Expand footer' : 'Collapse footer'}
            >
              <div className="flex flex-col items-center justify-center space-y-1">
                <span className="text-xl transition-colors duration-200">
                  {footer.icon}
                </span>
                <span className="text-xs font-medium">
                  {footer.title}
                </span>
              </div>
            </button>
          );
        } else {
          return (
            <NavLink
              id={`reels${footer.title}`}
              key={index}
              to={footer.link}
              className={`flex flex-col items-center justify-center ${
                collapsed ? 'hidden' : 'w-1/4'
              } h-full transition-transform duration-200 transform ${
                location.pathname === footer.link
                  ? 'text-white scale-110'
                  : 'text-gray-400 hover:scale-105'
              }`}
            >
              <div
                id={`reels2${footer.title}`}
                className={`flex flex-col items-center justify-center space-y-1 ${
                  location.pathname === footer.link ? 'text-white' : 'text-gray-400'
                }`}
              >
                <span
                  className={`text-xl transition-colors duration-200 ${
                    location.pathname === footer.link ? 'text-accent' : 'text-gray-400'
                  }`}
                >
                  {footer.icon}
                </span>
                <span
                  id="reels3"
                  className={`text-xs font-medium ${
                    location.pathname === footer.link ? 'text-white' : 'text-gray-400'
                  }`}
                >
                  {footer.title}
                </span>
              </div>
              {location.pathname === footer.link && (
                <span
                  className="absolute bottom-2 w-1 h-1 bg-accent rounded-full"
                  style={{ top: '10px', right: '25px' }}
                ></span>
              )}
            </NavLink>
          );
        }
      })}
    </div>
  );
};

export default Footer;
