
// Popup.jsx
import React, { useEffect, useState, useRef } from 'react';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import Confetti from 'react-confetti';


const Popup = ({ points }) => {


      const [showPopup, setShowPopup] = useState(false);
      const [popupPoints, setPopupPoints] = useState(0);

      const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      useEffect(() => {
        const handleResize = () => {
          setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
      
        useEffect(() => {
          const userTgID = window.Telegram.WebApp.initDataUnsafe?.user?.id;
        
          if (userTgID) {
            const localStorageKey = `userScore_${userTgID}`;
            const storedScore = parseInt(localStorage.getItem(localStorageKey)) || 0;
        
            if (storedScore > 0) {
              setPopupPoints(storedScore);
              setShowPopup(true);
              // Optionally, remove the score immediately or after popup is closed
              localStorage.removeItem(localStorageKey);
            }
          }
        }, []);
        
        const handleClosePopup = () => {
          setShowPopup(false);
          const userTgID = window.Telegram.WebApp.initDataUnsafe?.user?.id;
          if (userTgID) {
            const localStorageKey = `userScore_${userTgID}`;
            localStorage.removeItem(localStorageKey);
          }
        };


  return (
    <>
   
    {showPopup && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Confetti
            width={windowDimensions.width}
            height={windowDimensions.height}
            numberOfPieces={500}
            recycle={false}
            gravity={0.3}
          />
      <div className=" rounded-lg p-6 shadow-lg max-w-sm w-full text-center" style={{background:"#333333"}}>
        <h2 className="text-2xl font-bold mb-4">Congratulations!</h2>
       
            <div className="w-full items-center justify-center flex flex-col space-y-2">
                      <IoCheckmarkCircleSharp size={32} className={`text-accent`}/>
                      <p className='font-medium text-center'>Let's go!!</p>
                    </div>
                    <h3 className="font-medium text-[24px] text-[#ffffff] pt-2 pb-2">
        <span className={`text-accent`}>+{popupPoints}</span> XP
        </h3>
        <center>
        <button
          onClick={handleClosePopup}
          className="bg-btn4 text-[#000] w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
        >
          Close
        </button>
        </center>
      </div>
    </div>
    )}
    </>
  );
};

export default Popup;
