import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../context/userContext';
import { NavLink } from 'react-router-dom';
import { RiSettings4Fill } from 'react-icons/ri';

import Levels from '../Components/Levels';
import SettingsMenu from '../Components/SettingsMenu';
import Exchanges from '../Components/Exchanges';
import BalanceInfo from '../Components/BalanceInfo';

const Events = () => {
  const { fullName, balance, refBonus, selectedCharacter, selectedExchange } = useUser();

  const [showExchange, setShowExchange] = useState(false);
  const [info, setInfo] = useState(false);
  const [showLevel, setShowLevel] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const scrollRef = useRef(null);

  const [showLeaderboardModal, setShowLeaderboardModal] = useState(false);
  const [currentLeaderboard, setCurrentLeaderboard] = useState(null);

useEffect(() => {
    const handleTouchMove = (e) => {
      e.stopPropagation(); // Allow scroll within this element on touch devices
    };

    const scrollElement = scrollRef.current;

    if (scrollElement) {
      scrollElement.addEventListener('touchmove', handleTouchMove, { passive: true });
    }

    // Clean up the event listener on component unmount
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

useEffect(() => {
  const handleTouchMove = (e) => {
    e.stopPropagation(); // Allow scroll within this element on touch devices
  };

  const scrollElement = scrollRef.current;

  if (showLeaderboardModal && scrollElement) {
    scrollElement.addEventListener('touchmove', handleTouchMove, { passive: true });
  }

  // Cleanup when the modal is closed or the component unmounts
  return () => {
    if (scrollElement) {
      scrollElement.removeEventListener('touchmove', handleTouchMove);
    }
  };
}, [showLeaderboardModal]);

  useEffect(() => {

    const fetchEvents = async () => {
      try {
        const response = await fetch('https://fmguardians.com/backend/manage_events.php?action=fetch');
        const eventsList = await response.json();
        setEvents(eventsList.reverse());
      } catch (error) {
        console.error("Error fetching game events: ", error);
      }
    };
    fetchEvents();
  }, []);

  const handleNextEvent = () => {
    if (currentEventIndex < events.length - 1) {
      setCurrentEventIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePrevEvent = () => {
    if (currentEventIndex > 0) {
      setCurrentEventIndex(prevIndex => prevIndex - 1);
    }
  };

  const calculateCountdown = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const timeLeft = end - now;
    if (timeLeft <= 0) return null;

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    return { days, hours };
  };

  const openLeaderboardModal = async (leaderboard) => {
    // If leaderboard is already included in the event, just show it
    if (leaderboard) {
      setCurrentLeaderboard(leaderboard);
      setShowLeaderboardModal(true);
      return;
    }
    // Otherwise, just show an empty leaderboard
    setCurrentLeaderboard(null);
    setShowLeaderboardModal(true);
  };

  const closeLeaderboardModal = () => {
    setShowLeaderboardModal(false);
    setCurrentLeaderboard(null);
  };

  const renderLeaderboardModal = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-gray-800 p-6 rounded-lg w-full max-w-lg relative" style={{height:"90vh",backgroundColor:"#161210"}}>
          <h2 className="text-white text-xl font-bold mb-4 text-center">Leaderboard (Top 100)</h2>

          {currentLeaderboard ? (
            <div
            className="mt-2 bg-gray-700 rounded-lg p-2 block overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500"
            style={{ WebkitOverflowScrolling: "touch", overflowY: "scroll !important", height:"70vh",backgroundColor:"#241E1D" }}
           ref={scrollRef}>
              <ul className="text-gray-400 text-[14px]" >
                {Object.entries(currentLeaderboard)
                  .slice(0, 100)
                  .map(([userId, player], index) => (
                    <li key={userId} className="flex justify-between py-2 border-gray-600" style={{color:"#B9ACA9"}}>
                      <span>{index + 1}. <span class="text-[#fff]">{player.username}</span></span>
                      <span>{player.points} pts</span>
                    </li>
                  ))}
              </ul>
            </div>
          ) : (
            <p className="text-gray-400 text-center text-[14px]">Leaderboard data is not available yet.</p>
          )}

          <button
            onClick={closeLeaderboardModal}
            className="mt-4 bg-gradient-to-r from-yellow-400 to-yellow-600 text-black font-bold py-2 px-4 rounded-3xl w-full"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const renderEvent = (event) => {
    const now = new Date();
    const isExpired = new Date(event.endDate) < now;
    const countdown = isExpired ? null : calculateCountdown(event.endDate);

    return (
      <div className="relative bg-gray-800 p-6 rounded-lg shadow-lg mb-6 transition-all transform hover:scale-105 w-full max-w-md mx-auto" style={{backgroundColor: "#241E1D"}}>
        <div className="flex justify-center mb-4">
          <img
            src={event.icon}
            alt={event.name}
            className="w-24 h-24 object-cover rounded-full shadow-lg"
          />
        </div>

        <h2 className="text-white text-[20px] font-bold mb-2 text-center">{event.name}</h2>
        <p className="text-gray-300 text-[14px] mb-4 text-center">{event.description}</p>
        <p className="text-yellow-400 text-[14px] font-semibold text-center mb-4">
          Rewards: {event.rewards}
        </p>

        {isExpired && (
          <span className="absolute top-4 left-4 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded">
            Expired
          </span>
        )}

        {!isExpired && countdown && (
          <div className="text-center text-gray-400 text-sm">
            <p>{`Time Remaining: ${countdown.days} days ${countdown.hours} hours`}</p>
          </div>
        )}

        <div className="mt-6 flex flex-col items-center space-y-4">
          <NavLink
            to={`${event.url}?GameEvent=${event.id}`}
            className={`bg-gradient-to-r from-red-400 to-orange-600 text-white text-[18px] font-semibold rounded-3xl p-4 w-full text-center transition-all transform hover:scale-105 shadow-xl ${isExpired ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={(e) => isExpired && e.preventDefault()}
          >
            Play
          </NavLink>

          <button
            onClick={() => openLeaderboardModal(event.leaderboard)}
            className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black font-bold py-2 px-4 rounded-3xl w-full"
            disabled={!event.leaderboard}
          >
            Show Leaderboard
          </button>
        </div>

        <div className="absolute top-1/2 left-0 right-0 flex justify-between transform -translate-y-1/2">
          <button
            onClick={handlePrevEvent}
            className="p-4 rounded-full transition shadow-lg"
            disabled={currentEventIndex === 0}
          >
            <span className="text-[#FFA617] text-3xl">{"<"}</span>
          </button>
          <button
            onClick={handleNextEvent}
            className="p-4 rounded-full transition shadow-lg"
            disabled={currentEventIndex === events.length - 1}
          >
            <span className="text-[#FFA617] text-3xl">{">"}</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="overflow-y-auto h-screen scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-800  bg-cover bg-center"
        ref={scrollRef}
        style={{ marginTop: '-12px',backgroundImage: "url('/bg_tap.png')"}}
      >
        <div className="flex justify-between bg-[#1a1a1a] p-4 items-center bg-gray-800 bg-opacity-50 p-3 rounded-bl-[0px] rounded-br-[0px] shadow-lg" style={{backgroundColor: "#161210"}}>
          <div>
            <div className="flex items-center space-x-3">
              <div className="relative flex items-center justify-center overflow-hidden">
                <img
                  src={selectedCharacter.avatar}
                  className="w-12 h-12 rounded-full border-4 border-yellow-400 shadow-lg"
                  alt={fullName || 'user'}
                />
                <span className="absolute bottom-0 right-0 bg-green-500 w-4 h-4 rounded-full border-2 border-white"></span>
              </div>
              <div>
                <h1 className="text-lg font-bold text-white">{fullName}</h1>
                <span className="text-sm text-yellow-400 font-semibold">FM Guardian</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowExchange(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              style={{backgroundColor:"#010101"}}>
                <img
                  id={selectedExchange.id}
                  src={selectedExchange.icon}
                  alt={selectedExchange.name}
                  className="w-6 h-6"
                />
              </button>
              <button
                onClick={() => setShowSetting(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              style={{backgroundColor:"#010101"}}>
                <RiSettings4Fill size={24} className="text-white" />
              </button>
            </div>
            <div
              className="w-fit py-[2px] px-3 flex items-center space-x-1 justify-center border-[1px] border-[#241E1D] rounded-[25px]"
              style={{ marginTop: '18px' }}
            >
              <span className="w-[14px]">
                <img alt="engy" src="/loader.webp" className="w-full" />
              </span>
              <h1 className="text-[15px] text-[#75FF9E] font-bold">{balance + refBonus}</h1>
            </div>
          </div>
        </div>

        <h1 className="text-center text-[24px] mt-[18px] text-white font-bold">Game Events</h1>

        <div className="relative overflow-hidden h-[750px] scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-800 p-4 rounded-lg shadow-lg">
          {events.length > 0 ? renderEvent(events[currentEventIndex]) : <p className="text-center text-gray-400">Loading events...</p>}
        </div>
      </div>

      {showLeaderboardModal && renderLeaderboardModal()}

      <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
      <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
      <SettingsMenu showSetting={showSetting} setShowSetting={setShowSetting} />
      <BalanceInfo info={info} setInfo={setInfo} />
    </>
  );
};

export default Events;
